/** @jsxImportSource @emotion/react */
import Typography from "@/components/Typography";
import { css } from "@emotion/react";
import Backdrop from "@mui/material/Backdrop";
import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export const MaskContext = createContext<{
  onCreate: (config: MaskConfig) => void;
  onClose: () => void;
}>(undefined!);

export interface MaskConfig {
  onClose?: () => void;
  content: ReactNode;
  title?: ReactNode;
  maskCloseable?: boolean;
  zIndex?: number;
}

export const MaskProvider = memo((props: { children?: ReactNode }) => {
  const { children } = props;

  const [mask, setMask] = useState<MaskConfig | undefined>(undefined);

  const onCreate = useCallback((config: MaskConfig) => {
    setMask(config);
  }, []);

  const onClose = useCallback(() => {
    setMask(undefined);
    mask?.onClose?.();
  }, [mask]);

  const contextValue = useMemo(() => {
    return {
      onCreate,
      onClose,
    };
  }, [onClose, onCreate]);

  return (
    <MaskContext.Provider value={contextValue}>
      {mask ? (
        <Backdrop
          css={[
            css`
              background: rgba(0, 0, 0, 0.7);
              z-index: 1000;
            `,
            mask.zIndex &&
              css`
                z-index: ${mask.zIndex};
              `,
          ]}
          invisible={Boolean(mask)}
          open={Boolean(mask)}
          onClick={mask.maskCloseable ?? true ? onClose : undefined}
        >
          <div className="flex flex-col text-white justify-center items-center">
            {mask.title && (
              <div
                css={css`
                  margin-bottom: 24px;
                `}
              >
                <Typography type="title">{mask.title}</Typography>
              </div>
            )}
            <div>{mask.content}</div>
          </div>
        </Backdrop>
      ) : null}

      {children}
    </MaskContext.Provider>
  );
});

export function useMask() {
  const { onCreate } = useContext(MaskContext);
  return useCallback(
    (params: MaskConfig) => {
      onCreate(params);
    },
    [onCreate]
  );
}
export type UseMaskType = ReturnType<typeof useMask>;
