/** @jsxImportSource @emotion/react */
import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import { css } from "@mui/material";
import {
  ForwardedRef,
  forwardRef,
  memo,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useRef,
} from "react";
import { MuiTextFieldStyledProps } from "./TextField";

export type ButtonProps = {
  isLoading?: boolean;
  type?: "contained" | "outlined" | "text";
  isDisabled?: boolean;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  href?: string;
  size?: "small" | "medium" | "large";
  mode?: "antishake" | "throttle" | null;
  startIcon?: ReactNode;
  startIconSpace?: string;
  endIcon?: ReactNode;
  children?: ReactNode;
  className?: string;
  horizontalAlignment?: string;
  verticalAlignment?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
} & MuiTextFieldStyledProps;

export default memo(
  forwardRef(function Button(
    props: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) {
    const {
      isLoading,
      type,
      isDisabled,
      href,
      mode = "throttle",
      size,
      color,
      onClick,
      startIcon,
      startIconSpace,
      endIcon,
      className,
      children,
      textColor,
      borderColor,
      backGroundColor,
      fontSize,
      textFormat,
      horizontalAlignment,
      verticalAlignment,
      ...restProps
    } = props;

    const getAlignment = useCallback((value: string | undefined) => {
      switch (value) {
        case "start":
          return "flex-start";
        case "center":
          return "center";
        case "end":
          return "flex-end";
        case "stretch":
          return "stretch";
        default:
          return "flex-start";
      }
    }, []);

    const timerRef = useRef<ReturnType<typeof setTimeout> | undefined>(
      undefined
    );
    const canClickRef = useRef<boolean>(true);

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        switch (mode) {
          case "antishake":
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
              onClick?.(event);
              timerRef.current = undefined;
            }, 500);
            break;
          case "throttle":
            if (canClickRef.current) {
              canClickRef.current = false;
              onClick?.(event);
              setTimeout(() => {
                canClickRef.current = true;
              }, 500);
            }
            break;
          default:
            onClick?.(event);
            break;
        }
      },
      [onClick, mode]
    );

    return (
      <MuiLoadingButton
        css={[
          borderColor &&
            css`
              border-color: ${borderColor};
            `,
          textColor &&
            css`
              color: ${textColor ?? "#333"};
            `,
          backGroundColor &&
            css`
              background-color: ${backGroundColor};
              :hover {
                background-color: ${backGroundColor};
              }
            `,
          verticalAlignment &&
            css`
              align-items: ${getAlignment(verticalAlignment)};
            `,
          horizontalAlignment &&
            css`
              justify-content: ${getAlignment(horizontalAlignment)};
            `,
          css`
            text-transform: unset;
            font-size: ${fontSize ?? "12px"};
            text-decoration: ${textFormat ?? "none"};
            :hover {
              text-decoration: ${textFormat ?? "none"};
            }
            min-width: unset;
            align-items: center;
            .MuiButton-startIcon {
              margin-right: ${startIconSpace ?? "4px"};
              margin-left: unset;
              align-self: center;
            }
            &:not(.MuiButton-text).Mui-disabled {
              background: #e2e2e2;
            }
          `,
          type === "contained" &&
            css`
              padding: 0px;
            `,
        ]}
        className={className}
        disabled={isDisabled}
        variant={type}
        href={href}
        size={size}
        ref={ref}
        color={color}
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={handleClick}
        {...restProps}
      >
        {children}
      </MuiLoadingButton>
    );
  })
);
