import { EncooHttpService } from "@/services/EncooHttpService";
import {
  ChatRequestConversationPayload,
  ChatRequestMessage,
  ChatRequestMessageContent,
  ChatRequestMessageRoleEnum,
} from "@/services/apis/EncooBeeChatHttpClient";

import {
  Conversation,
  ConversationChannelEnum,
  ConversationEnum,
  ConversationMessageBaseType,
  ConversationMessageExtensionEnum,
  ConversationMessageGroupEnum,
  ConversationMessageGroupType,
  ConversationMessageRoleEnum,
  ConversationMessageSenderEnum,
  ConversationMessageType,
  ConversationRobotMessageType,
  ConversationUserMessageType,
} from "@/services/models/chart";
import { useChartQuotaStore } from "@/store/chartQuota";
import { useLoginUserStore } from "@/store/loginUser";
import { useConversationRoleStore } from "@/store/role";
import { newGuid } from "@/utils/shared";
import { produce } from "immer";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { Subject } from "rxjs/internal/Subject";
import { create } from "zustand";

export type ChatUserMessageParams = Omit<
  ConversationUserMessageType,
  "sender" | "id" | "error" | "createTime"
>;

export const createRobotChatMessage = <
  T extends Omit<ConversationRobotMessageType, "createTime" | "id">
>(
  config: T
): ConversationRobotMessageType =>
  Object.assign({ createTime: Date.now(), id: newGuid() }, config);

export const createUserChatMessage = <
  T extends Omit<
    ConversationUserMessageType,
    "createTime" | "id" | "nickName" | "picture"
  >
>(
  config: T
): ConversationUserMessageType => {
  const { nickName = "", picture = "" } =
    useLoginUserStore.getState().userProfile ?? {};
  return Object.assign(
    { createTime: Date.now(), id: newGuid(), nickName, picture },
    config
  );
};

export const createShareMessageGroup = <
  T extends Omit<ConversationMessageGroupType, "type" | "id" | "createTime">
>(
  config: T
): ConversationMessageGroupType => {
  return Object.assign(
    {
      createTime: Date.now(),
      id: newGuid(),
      type: ConversationMessageGroupEnum["group"],
    },
    config
  );
};

export const createConversation = <
  T extends Omit<Conversation, "createTime" | "id" | "lastModifyTime" | "type">
>(
  config: T
): Conversation => {
  const time = Date.now();
  return Object.assign(
    {
      lastModifyTime: time,
      createTime: time,
      id: newGuid(),
      type: ConversationEnum["conversation"],
    },
    config
  );
};

export const createConversationContentMessage = (
  config: ChatRequestMessageContent
): ChatRequestMessageContent => {
  return config;
};

export const isConversation = (data: Conversation): data is Conversation => {
  return data.type === ConversationEnum["conversation"];
};

export const isUserChatMessage = (
  message: ConversationMessageType
): message is ConversationUserMessageType => {
  return message.sender === ConversationMessageSenderEnum["user"];
};

export const isRobotChatMessage = (
  message: ConversationMessageType
): message is ConversationRobotMessageType => {
  return message.sender === ConversationMessageSenderEnum["robot"];
};
export const isShareChatGroupMessage = (
  message: ConversationMessageType
): message is ConversationMessageGroupType => {
  return message.type === ConversationMessageGroupEnum["group"];
};

export interface ChatHelperState {
  changeMode: (
    messages: ChatRequestMessage[],
    extensions?: ConversationMessageExtensionEnum[]
  ) => ChatRequestMessage[];
  sendMessage: (payload: {
    sendedMessages?: ConversationMessageType[];
    userMessage: ConversationUserMessageType;
  }) => Subject<ConversationRobotMessageType>;
}

const getErrorMessage = (error: Error): string => {
  if (error instanceof Error) {
    return error.message;
  }
  try {
    return JSON.stringify(error, undefined, 2);
  } catch (err) {
    return "" + error;
  }
};
export const getCanUseMessages = (messages: ConversationMessageBaseType[]) => {
  return messages.filter((item) => item.deleteTime === undefined);
};

export const useChartHelperStore = create<ChatHelperState>((set, get) => ({
  changeMode: (messages, extensions) => {
    if (
      extensions &&
      extensions[0] === ConversationMessageExtensionEnum["highQualityImage"]
    ) {
      return messages;
    } else {
      const newMessages = messages.map((mes) => {
        if (mes.role === "user") {
          if (typeof mes.content === "string") {
            return mes;
          } else {
            return Object.assign(mes, {
              content:
                mes.content.filter((item) => item.type === "text")?.[0]?.[
                  "text"
                ] ?? "",
            });
          }
        } else {
          return mes;
        }
      });
      return newMessages;
    }
  },
  sendMessage: (payload) => {
    const { changeMode } = get();
    const { sendedMessages = [], userMessage } = payload;
    const { channel, role } = userMessage;
    const { roles } = useConversationRoleStore.getState();

    const messages: ChatRequestMessage[] =
      [sendedMessages, userMessage]
        .flat()
        .filter((item) => !(isRobotChatMessage(item) && item.error))
        .map((c) => ({
          role:
            c.sender === ConversationMessageSenderEnum.robot
              ? ChatRequestMessageRoleEnum.Assistant
              : ChatRequestMessageRoleEnum.User,
          content: c.message ?? "",
          transform: c.transform,
        })) ?? [];
    let prompt = "";
    const prompts: ChatRequestMessage[] = [];
    for (let i = messages?.length - 1; i >= 0; i--) {
      const temp = messages[i].content + prompt;
      if (prompt.length > 8000) {
        break;
      }
      prompt = temp;
      prompts.push(messages[i]);
    }
    let robotMessage = createRobotChatMessage({
      id: newGuid(),
      sender: ConversationMessageSenderEnum["robot"],
      message: "",
      role: userMessage.role,
      channel: userMessage.channel,
      questionId: userMessage.id,
      context: undefined,
      extensions: userMessage.extensions,
    });
    const subject = new BehaviorSubject<ConversationRobotMessageType>(
      robotMessage
    );

    let conversationPayload: ChatRequestConversationPayload | undefined =
      undefined;

    switch (role) {
      case ConversationMessageRoleEnum.cat:
        conversationPayload = { role: ConversationMessageRoleEnum.cat };
        break;
      case ConversationMessageRoleEnum.smart:
        conversationPayload = {
          role: ConversationMessageRoleEnum.smart,
          payload: roles[ConversationMessageRoleEnum.smart]?.payload ?? {
            mode: "persons",
            items: [],
          },
        };
        break;
    }

    EncooHttpService.chart.sendMessage({
      prompt: changeMode(prompts.reverse(), userMessage.extensions),
      channel:
        channel === ConversationChannelEnum["gpt3"] ? undefined : channel,
      extensions: userMessage.extensions,
      conversationPayload,
      onRead: (text: string) => {
        robotMessage = produce(robotMessage, (draft) => {
          draft.message += text;
        });
        subject.next(robotMessage);
      },
      onDone: () => {
        subject.complete();
        useChartQuotaStore.getState().refresh();
      },
      onError: (error: Error) => {
        robotMessage = produce(robotMessage, (draft) => {
          Object.assign(draft, {
            error: getErrorMessage(error),
          });
        });
        subject.error(robotMessage);
      },
    });
    return subject;
  },
}));
