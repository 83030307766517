/** @jsxImportSource @emotion/react */
import Button from "@/components/Button";
import { MaskImage } from "@/components/MaskImage";
import { TaskStorage, createTask } from "@/containers/Tasks";
import ImgPromotional from "@assets/img/tasks/task-promotional.png";
import { css } from "@emotion/react";
import dayjs from "dayjs";

export const PromotionalTask = createTask({
  name: "促销倒计时",
  description: "需求到10.31日  每日展示",
  runCondition: function useRunCondition() {
    const storeDate = TaskStorage.getItem("promotional");
    const nowDate = dayjs().format("YYYY-MM-DD");
    if (nowDate <= "2023-10-31") {
      if (!storeDate || nowDate > storeDate) {
        return true;
      } else return false;
    } else {
      return false;
    }
  },
  onRun: async ({ mask, navigate }, next) => {
    const nowDate = dayjs().format("YYYY-MM-DD");
    TaskStorage.setItem("promotional", nowDate);
    mask({
      content: (
        <div className="text-center">
          <MaskImage
            src={ImgPromotional}
            onClick={() => window.open("https://chat.beezy.cool/payment")}
          />
          <div
            css={css`
              margin-top: 20px;
            `}
          >
            <Button
              type="text"
              onClick={() => window.open("https://chat.beezy.cool/payment")}
            >
              点击链接→ 即可购买
            </Button>
          </div>
        </div>
      ),
      onClose: next,
    });
  },
});
