/** @jsxImportSource @emotion/react */
import { RoleSkinProps } from "@/containers/Routers/Chart/skins/shared";
import { css } from "@emotion/react";
import { memo } from "react";
export const Basic = memo(function (props: RoleSkinProps) {
  const { children, error } = props;
  return (
    <div
      className="w-full"
      css={[
        css`
          position: relative;
          border: 2px solid #840117;
          border-radius: 10px;
          padding: 2px;
          border-bottom-left-radius: unset;
        `,
        error &&
          css`
            background-color: #fce5e5;
          `,
      ]}
    >
      <div
        css={css`
          border: 0.4px dashed #cd2f4a;
          border-radius: 10px;
          padding: 2px;
          border-bottom-left-radius: unset;
          padding: 10px 6px;
        `}
      >
        {children}
      </div>
    </div>
  );
});
