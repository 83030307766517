import { AuthNavigateGuard } from "@/containers/Guards/AuthNavigate";
import { appConfig } from "@/shared/config/common";
import { useLoginUserStore } from "@/store/loginUser";
import cssGlobal from "@/styles/global";
import { Global } from "@emotion/react";
import { init } from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import { createRoot } from "react-dom/client";
import VConsole from "vconsole";
import App from "./App";

try {
  if (appConfig.env !== "development") {
    init({
      dsn: "https://c62d66eef6bf47c6a308424daf7ad9cc@sentry.bottime.com/2",
      integrations: [new BrowserTracing()],
      debug: false,
      tracesSampleRate: 1.0,
      environment: process.env.NODE_ENV,
    });
  }
  if (window.location.origin === "https://honeybee-dev.bottime.com") {
    new VConsole();
  }
} catch (error) {
  console.log(error);
}

if (!appConfig.platform.isMobile && !appConfig.platform.isWeixin) {
  window.location.replace(appConfig.pcUrl);
} else {
  const root = createRoot(document.getElementById("root")!);
  if (!AuthNavigateGuard.runCondition()) {
    useLoginUserStore.getState().init();
  }
  root.render(
    <React.Fragment>
      <Global styles={cssGlobal} />
      <App />
    </React.Fragment>
  );
}
