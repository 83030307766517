import { HttpClientMiddleware } from "@/services/apis/shared";

export const createBaseUrlMiddleware = (
  getBaseUrl: () => string
): HttpClientMiddleware => {
  return async (config, next) => {
    if (config.baseURL === undefined) {
      config.baseURL = getBaseUrl();
    }
    return next(config);
  };
};
