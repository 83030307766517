import { routerKeyUnion } from "@/containers/Routers/configs";
import { ParseFormatStringKeys } from "@/types/string";
import { formatString } from "@/utils/string";

type ShareDataType<T, U> = {
  title: T;
  desc: U;
};

const createShare =
  <const T extends string, const U extends string>(data: ShareDataType<T, U>) =>
  (
    params?: Record<
      Exclude<
        ParseFormatStringKeys<T>[number] | ParseFormatStringKeys<U>[number],
        never
      >,
      string | number | undefined
    >
  ) => {
    return {
      title: formatString(data.title, params),
      desc: formatString(data.desc, params),
    };
  };

const createWxShareConfig = function <
  T extends Partial<Record<routerKeyUnion, unknown>>
>(config: T) {
  return config;
};

export const wxShareConfig = createWxShareConfig({
  solitaire: createShare({
    title: "快来和我一起Beezy接龙",
    desc: "`{nickName}` 邀你快来使用Beezy实现聊天接龙游戏 登录即可体验免费5次GPT4o额度",
  }),
  chart: createShare({
    title: "Beezy | 喳喳蜂",
    desc: `邀你一起用Beezy实现聊天自由，登录即可体验免费5次GPT4o额度`,
  }),
});
