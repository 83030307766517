/** @jsxImportSource @emotion/react */
import { newGuid } from "@/utils/shared";
import { css } from "@emotion/react";
import { ReactNode, memo, useEffect, useMemo, useState } from "react";
//TODO 需要改成事件驱动 支持context
export type GuardStateType = {
  description?: string;
  key: string;
  runCondition: () => boolean;
  render?: () => ReactNode;
  onRun?: () => void;
  subscribe?: (runTask: () => void) => void;
};

const guardSubscribeMap = new Map<string, () => void>();
export const createGuard = (
  config: Omit<GuardStateType, "key">
): GuardStateType => {
  const key = newGuid();
  if (config.subscribe) {
    config.subscribe(() => {
      guardSubscribeMap.get(key)?.();
    });
  }
  return Object.assign({ key }, config);
};

export const GuardContainer = memo(
  (props: { guards?: GuardStateType[]; children?: ReactNode }) => {
    const { guards = [], children } = props;
    const [updateKey, setUpdateKey] = useState("");

    const guardConfig = useMemo(() => {
      return guards.find((item) => item.runCondition());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guards, updateKey]);

    const guardRender = useMemo(() => {
      return guardConfig?.render?.();
    }, [guardConfig]);

    useEffect(() => {
      guards.forEach((item) => {
        guardSubscribeMap.set(item.key, () => {
          setUpdateKey(newGuid());
        });
      });
    }, [guards]);

    useEffect(() => {
      if (guardConfig?.onRun) {
        guardConfig.onRun();
      }
    }, [guardConfig]);

    return (
      <div className="flex w-full h-full">
        <div
          className="flex flex-1 relative"
          css={css`
            min-width: 0;
            min-height: 100vh;
          `}
        >
          {guardRender || children}
        </div>
      </div>
    );
  }
);
