import { HttpClient } from "@/services/apis/shared";
import { ConversationMessageType } from "@/services/models/chart";
import {
  ChartHistory,
  ChartHistoryContent,
} from "@/services/models/chatHistory";
import { newGuid } from "@/utils/shared";

type ChartCreateHistoryType = Omit<ChartHistory, "context"> & {
  context: ChartHistoryContent;
};

export class EncooChartHistoryServiceHttpClient extends HttpClient {
  public async getHistory(historyId: string): Promise<ChartCreateHistoryType> {
    const { data } = await this.request<ChartHistory>({
      method: "GET",
      url: `/payment/api/ChatHistories/${historyId}`,
    });
    const { context, ...otherData } = data;
    const content: ChartHistoryContent = JSON.parse(context);
    return Object.assign({ context: content }, otherData);
  }
  public async createHistory(payload: {
    messages?: ConversationMessageType[];
  }) {
    const { messages = [] } = payload;
    const { data } = await this.request<ChartHistory>({
      method: "POST",
      url: `/payment/api/ChatHistories`,
      data: {
        content: JSON.stringify({
          id: newGuid(),
          createTime: Date.now(),
          messages: messages,
        }),
      },
    });
    return data;
  }
}
