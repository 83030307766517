/** @jsxImportSource @emotion/react */
import { GuardContainer } from "@/containers/Guards";
import { AuthErrorGuard } from "@/containers/Guards/AuthError";
import { AuthLoadingGuard } from "@/containers/Guards/AuthLoading";
import { AuthNavigateGuard } from "@/containers/Guards/AuthNavigate";
import { MigrationContainer } from "@/containers/Migrations";
import LazyRouteElement from "@/containers/Routers/_internal/LazyRouteElement";
import { routerConfigs } from "@/containers/Routers/configs";
import { memo } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
//TODO 需要改造 routerConfigs 支持嵌套
export default memo(() => {
  // let { search, hash } = useLocation();
  return (
    <BrowserRouter>
      <Routes>
        {routerConfigs.map((item) => (
          <Route
            key={item.path}
            element={
              <GuardContainer
                guards={[
                  AuthNavigateGuard,
                  AuthLoadingGuard,
                  AuthErrorGuard,
                  item.guards ?? [],
                ].flat()}
              >
                <MigrationContainer migrations={item.migrations}>
                  <Outlet />
                </MigrationContainer>
              </GuardContainer>
            }
          >
            <Route
              path={item.path}
              element={
                <LazyRouteElement path={item.path} element={item.element} />
              }
            />
          </Route>
        ))}
      </Routes>
    </BrowserRouter>
  );
});
