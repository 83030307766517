import { EncooHttpService } from "@/services/EncooHttpService";
import { ChartQuota, ChatChannelEnum } from "@/services/models/chart";
import { createMapFromArray } from "@/utils/array";
import { create } from "zustand";

export interface ChartQuotaStoreState {
  refresh: () => Promise<void>;
  quota3?: ChartQuota;
  quota4?: ChartQuota;
  imageQuota?: ChartQuota;
}

export const useChartQuotaStore = create<ChartQuotaStoreState>((set, get) => ({
  refresh: async () => {
    const quotas = await EncooHttpService.quota.getQuotas();
    const quotaMap = createMapFromArray(quotas, "serviceType");
    set({
      quota3: {
        max: quotaMap[ChatChannelEnum["gpt3"]]?.quota ?? 0,
        used: quotaMap[ChatChannelEnum["gpt3"]]?.usege ?? 0,
      },
      quota4: {
        max: quotaMap[ChatChannelEnum["gpt4"]]?.quota ?? 0,
        used: quotaMap[ChatChannelEnum["gpt4"]]?.usege ?? 0,
      },
      imageQuota: {
        max: quotaMap[ChatChannelEnum["image"]]?.quota ?? 0,
        used: quotaMap[ChatChannelEnum["image"]]?.usege ?? 0,
      },
    });
  },
}));
