import { errorCodeMap } from "@/services/config";
import {
  createAuthorizationMiddleware,
  createBaseUrlMiddleware,
  createErrorMiddleware,
  isCustomErrorResponse,
} from "@/services/middlewares";
import { getMiddlewareByUrlPath } from "@/services/middlewares/scuess";
import { useNotificationStore } from "@/store/notification";
import { setUser } from "@sentry/react";

import { UserProfile, WXConfig } from "@/services/models/user";
import { useAuthStore } from "@/store/auth";
import { captureException } from "@sentry/react";
import get from "lodash-es/get";
import isPlainObject from "lodash-es/isPlainObject";

const setMessage = useNotificationStore.getState().setMessage;
const setTokenStatus = useAuthStore.getState().setTokenStatus;
export class EncooHttpServiceBase {
  public getAccessToken = createAuthorizationMiddleware(async () => {
    const token = useAuthStore.getState().token;
    return token ?? "";
  });
  public getBeeBaseUrl = createBaseUrlMiddleware(() => {
    return window._setting.api.baseUrl;
  });
  public getHoneyBeeBaseUrl = createBaseUrlMiddleware(() => {
    return window._setting.auth.baseUrl;
  });

  public setSentryUserFromProfile = getMiddlewareByUrlPath<UserProfile>(
    "/v1/profile",
    (res) => {
      setUser({
        username: res.responseData.nickName,
        id: res.responseData.id,
      });
    }
  );
  public setSentryUserFromWx = getMiddlewareByUrlPath<WXConfig>(
    "/v1/WechatSecurite/RequestShareSignature",
    (res) => {
      setUser({
        appId: res.responseData.appId,
      });
    }
  );
  public applyErrorMiddle = createErrorMiddleware((err) => {
    if (err.status === 401) {
      setTokenStatus("expired");
    } else if (err.responseData && isCustomErrorResponse(err.responseData)) {
      const { errorCode, message } = err.responseData;
      const _message = errorCodeMap[errorCode] ?? message;
      setMessage({
        type: "error",
        title: _message,
      });
    } else if (
      isPlainObject(err.responseData) &&
      "message" in err.responseData
    ) {
      setMessage({
        type: "error",
        title: String(err.responseData["message"]),
      });
    }
    captureException(new Error("Net Error URL:".concat(err.url)), (scope) => {
      scope.setLevel("error");
      scope.setTags({
        requestId: get(err.responseData, "requestId"),
      });
      scope.setExtra("errorData", err);
      return scope;
    });
  });
}
