import { ConfirmConfig } from "@/containers/Providers/Confirm";
import { create } from "zustand";

export interface ConfirmStorState {
  config?: Omit<ConfirmConfig, "key">;
  setConfig: (config?: ConfirmStorState["config"]) => void;
}
export const useConfirmStore = create<ConfirmStorState>((set) => ({
  config: undefined,
  setConfig: (config) => set({ config }),
}));
