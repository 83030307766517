import {
  HttpClientMiddleware,
  isAxionsResponse,
  isFeatchResponse,
} from "@/services/apis/shared";
import { AxiosResponse, Method } from "axios";
export type MiddlewareScuessMessage<T = any> = {
  url: string;
  path: string;
  status: number;
  statusText: string;
  method: Method | string;
  responseData: T;
  requsetData: unknown;
  params?: unknown;
};

export const createScuessMiddleware = <T>(
  onScuess: (res: MiddlewareScuessMessage<T>) => void
): HttpClientMiddleware => {
  return async (config, next) => {
    const res = (await next(config)) as Response | AxiosResponse;
    if (res.status === 200 && isFeatchResponse(res)) {
      const data = await res.clone().json();
      onScuess({
        url: res.url,
        requsetData: config.data,
        path: config.url!,
        statusText: res.statusText,
        status: res.status,
        method: config.method!,
        responseData: data,
      });
    } else if (res.status === 200 && isAxionsResponse(res)) {
      onScuess({
        url: res.request.responseURL,
        requsetData: res.config.data,
        path: config.url!,
        params: res.config.params,
        status: res.status,
        statusText: res.statusText,
        method: config.method!,
        responseData: res.data,
      });
    }
    return res;
  };
};
export const getMiddlewareByUrlPath = <T>(
  path: string,
  callback: (res: MiddlewareScuessMessage<T>) => void
) =>
  createScuessMiddleware<T>((res) => {
    if (res.path === path) {
      callback(res);
    }
  });
