/** @jsxImportSource @emotion/react */
import SvgIcon from "@/components/SvgIcon";
import { ReactComponent as IconLoading } from "@assets/img/icon-loading.svg";
import { css, keyframes } from "@emotion/react";
import Fade from "@mui/material/Fade";
import {
  ReactNode,
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
export const LoadingContext = createContext<{
  onOpen: () => void;
  onClose: () => void;
}>(undefined!);

export const LoadingProvider = memo((props: { children?: ReactNode }) => {
  const { children } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const onOpen = useCallback(() => {
    setLoading(true);
  }, []);

  const onClose = useCallback(() => {
    setLoading(false);
  }, []);

  const contextValue = useMemo(() => {
    return {
      onOpen,
      onClose,
    };
  }, [onClose, onOpen]);

  return (
    <LoadingContext.Provider value={contextValue}>
      <Fade in={loading}>
        <div
          css={css`
            position: fixed;
            width: 100vw;
            height: 100vh;
            left: 0;
            z-index: 1000;
          `}
        >
          <div
            className="inset-0 flex items-center justify-center"
            css={css`
              position: absolute;
              border-radius: 10px;
              width: 100px;
              height: 100px;
              background: rgba(0, 0, 0, 0.5);
              color: #fff;
              padding: 10px 16px;
              margin: auto;
            `}
          >
            <SvgIcon
              css={css`
                animation: ${spin} 2.4s infinite;
                animation-timing-function: linear;
                opacity: 0.9;
              `}
              SvgComponent={IconLoading}
              value={60}
            />
          </div>
        </div>
      </Fade>
      {children}
    </LoadingContext.Provider>
  );
});

export function useLoading() {
  const { onOpen, onClose } = useContext(LoadingContext);
  return useMemo(
    () => ({
      onOpen,
      onClose,
    }),
    [onClose, onOpen]
  );
}
