import { RoutePathUnion } from "@/containers/Routers/configs";
import { useCallback } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";
interface RoutePath {
  pathname: RoutePathUnion;
  search?: string;
  hash?: string;
}
export function useNavigateTo() {
  const navigate = useNavigate();
  return useCallback(
    (path: RoutePathUnion | RoutePath, options?: NavigateOptions) => {
      navigate(path, options);
    },
    [navigate]
  );
}
export type UseNavigateType = ReturnType<typeof useNavigateTo>;
