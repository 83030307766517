/** @jsxImportSource @emotion/react */
import { PageLoading } from "@/components/Loading";
import { createGuard } from "@/containers/Guards";
import { useLoginUserStore } from "@/store/loginUser";

export const SubscriptionLoadingGuard = createGuard({
  description: "必要接口是否执行完毕",
  runCondition: function () {
    return (
      useLoginUserStore.getState().isFollowLoading ||
      !useLoginUserStore.getState().userProfile
    );
  },
  render: () => <PageLoading />,
  subscribe: (runGuard) => {
    useLoginUserStore.subscribe(() => runGuard());
  },
});
