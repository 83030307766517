import { HttpClient } from "@/services/apis/shared";
import { GenerationImageContext, SizeType } from "@/services/models/drawing";

export class EncooDrawingHttpClient extends HttpClient {
  public async generateImage(payload: {
    prompt: string;
    sizeType: SizeType;
    imageFormat?: string;
    skipToFail?: () => void;
  }) {
    const { prompt, sizeType, imageFormat = "b64_json", skipToFail } = payload;
    try {
      const { data } = await this.request<{
        data: GenerationImageContext[];
      }>({
        method: "POST",
        url: `/honeybee/ImageGenerator`,
        data: { prompt, sizeType, imageFormat },
      });
      return data;
    } catch (err) {
      await skipToFail?.();
      console.error("err", err);
    }
  }
}
