import { createStorage } from "@/utils/localStorage";
import { Type } from "@sinclair/typebox";

const AppStorageStateSchema = Type.Object({
  token: Type.String(),
  notShowDisclaimer: Type.Boolean(),
  canRecord: Type.Boolean(),
  guidePosition: Type.Number(),
  expiryTime: Type.String(),
});

export const AppStorage = createStorage("bee-app", AppStorageStateSchema);
