import { EncooHttpService } from "@/services/EncooHttpService";
import { PayOrder } from "@/services/models/order";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

export interface OrderStoreState {
  orders: PayOrder[];
  refreshOrdersClient: () => Promise<PayOrder[]>;
  createOrdersClient: (
    items?: { productId: string; quantity: number },
    couponCode?: string
  ) => Promise<PayOrder>;
}

export const useOrderStore = create(
  subscribeWithSelector<OrderStoreState>((set, get) => ({
    orders: [],
    refreshOrdersClient: async () => {
      const orders = await EncooHttpService.order.getOrders();
      set({ orders });
      return orders;
    },
    createOrdersClient: async (items, couponCode) => {
      const orders = await EncooHttpService.order.createOrder(
        items,
        couponCode
      );
      return orders;
    },
  }))
);
