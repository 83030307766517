/** @jsxImportSource @emotion/react */
import Button from "@/components/Button";
import { TaskStorage, createTask } from "@/containers/Tasks";
import { css } from "@emotion/react";

export const MagicTask = createTask({
  name: "魔法棒使用介绍",
  description: "魔法棒使用介绍只在首次使用出现",
  runCondition: () => {
    const magicShowed = TaskStorage.getItem("magicIntroduce");
    return !Boolean(magicShowed);
  },
  onRun: async ({ mask }, next) => {
    mask({
      content: (
        <div>
          <div
            css={css`
              position: absolute;
              display: flex;
              align-items: center;
              flex-direction: column;
              left: 20px;
              right: 20px;
              bottom: 65px;
            `}
          >
            <div
              css={css`
                padding: 20px;
                border-radius: 10px;
                border: 2px solid rgba(255, 255, 255, 0.5);
                font-size: 12px;
              `}
            >
              <div
                css={css`
                  font-size: 16px;
                  margin-bottom: 12px;
                `}
              >
                💡什么是魔法棒？
              </div>

              <div
                css={css`
                  margin-bottom: 12px;
                `}
              >
                {"要想玩转GPT你需要精通一门<"}
                <span
                  css={css`
                    font-weight: bold;
                    color: #ffcc00;
                  `}
                >
                  提问的艺术
                </span>
                {">,因为优质的问题会带动优质的GPT回答。"}
              </div>
              <div
                css={css`
                  margin-bottom: 12px;
                `}
              >
                不需要学习提示词的撰写，只要点击一下“
                <span
                  css={css`
                    font-weight: bold;
                    color: #ffcc00;
                  `}
                >
                  魔法棒
                </span>
                ”
                <img
                  css={css`
                    display: inline;
                    margin: 0 3px;
                  `}
                  src="/images/magic-i.png"
                  width="18"
                  height="18"
                  alt=""
                ></img>
                您的提示词就能即刻优化。
              </div>
              <div>
                ⚠注意：请确保输入框内有内容后再使用 每次使用将消耗1次GPT4o
                mini额度～
              </div>
            </div>

            <Button
              backGroundColor="#fff"
              textColor="rgba(61, 61, 61, 0.85)"
              css={css`
                padding: 8px 20px;
                margin-top: 25px;
                border-radius: 4px;
              `}
              onClick={(e) => {
                TaskStorage.setItem("magicIntroduce", true);
                next();
              }}
            >
              我知道了 不再提示
            </Button>
          </div>
          <img
            css={css`
              position: absolute;
              left: 40px;
              bottom: 55px;
            `}
            src="/images/magic-line.png"
            alt=""
          />
          <img
            css={css`
              position: absolute;
              left: 15px;
              bottom: 25px;
            `}
            src="/images/magic-i.png"
            alt=""
          />
        </div>
      ),
      onClose: next,
    });
  },
});
