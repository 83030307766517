import {
  chatSkinConfigByRole,
  ChatSkinConfigMapType,
} from "@/containers/Routers/Chart/skins";
import { EncooHttpService } from "@/services/EncooHttpService";
import {
  Conversation,
  ConversationEntryEnum,
  ConversationMessageRoleEnum,
  ConversationMessageSenderEnum,
  ConversationMessageType,
  ConversationReplyingMessage,
  ConversationRobotMessageType,
  ConversationUserMessageType,
} from "@/services/models/chart";
import {
  ChatUserMessageParams,
  createUserChatMessage,
  isUserChatMessage,
  useChartHelperStore,
} from "@/store/chartHelper";
import { useChartInputStore } from "@/store/chartInput";
import { useChartQuotaStore } from "@/store/chartQuota";
import produce from "immer";
import { findLastIndex, nth } from "lodash-es";
import last from "lodash-es/last";
import { Subscription } from "rxjs";
import { create } from "zustand";
import {
  createJSONStorage,
  persist,
  subscribeWithSelector,
} from "zustand/middleware";
import { createConversation } from "./chartHelper";
export interface ConversationsPropsState {
  replyingMessage?: ConversationReplyingMessage;
  subscription?: Subscription;
  setReplyingMessage: (replyingMessage?: ConversationReplyingMessage) => void;
  reply: (payload: {
    conversationId: string;
    userMessage: ConversationUserMessageType;
  }) => Promise<void>;
  stop: () => Promise<void>;
}
export type ConversationCreateProps = Omit<
  Conversation,
  "id" | "createTime" | "lastModifyTime"
>;

export type ConversationUpdateProps = Partial<Conversation> &
  Pick<Conversation, "id">;

export interface ConversationListState {
  conversations: Conversation[];
  setConversations: (conversations: Conversation[]) => void;
  conversationRole: ConversationMessageRoleEnum;
  create: (conversation: {
    title: string;
    userMessageParams: ChatUserMessageParams;
  }) => Conversation;
  clear: () => void;
  addMessage: (payload: {
    conversionId: string;
    userMessageParams: ChatUserMessageParams;
  }) => Promise<void>;
  insetToConversationMessages: (payload: {
    conversionId: string;
    conversationMessage: ConversationMessageType;
  }) => Promise<void>;

  resend: (conversionId: string) => void;
  clearErrorMessage: (conversionId: string) => void;
  getRoleConfig: (
    role?: ConversationMessageRoleEnum
  ) => ChatSkinConfigMapType[ConversationMessageRoleEnum];
  setConversationRole: (role?: ConversationMessageRoleEnum) => void;
}

export interface ConversationState {
  getConversion: () => Conversation | undefined;
}

const sendMessage = useChartHelperStore.getState().sendMessage;

export const useConversationsPropsStore = create(
  subscribeWithSelector<ConversationsPropsState>((set, get) => ({
    replyingMessage: undefined,
    setReplyingMessage: (replyingMessage?: ConversationReplyingMessage) =>
      set({ replyingMessage }),
    stop: async () => {
      const { replyingMessage, subscription } = get();
      const { insetToConversationMessages } =
        useConversationListStore.getState();
      if (replyingMessage) {
        const { conversationId } = replyingMessage;
        subscription?.unsubscribe();
        await insetToConversationMessages({
          conversionId: conversationId,
          conversationMessage: replyingMessage,
        });
        useChartQuotaStore.getState().refresh();
        set({ replyingMessage: undefined, subscription: undefined });
      }
    },
    reply: async (payload) => {
      const { conversationId, userMessage } = payload;
      const { conversations, setConversations } =
        useConversationListStore.getState();
      const { insetToConversationMessages } =
        useConversationListStore.getState();
      const updateConversions = produce(conversations, (draft) => {
        const conversation = draft.find((c) => c.id === conversationId);
        if (conversation) {
          conversation.lastModifyTime = Date.now();
          conversation.messages.push(userMessage);
        }
      });
      setConversations(updateConversions);
      const conversation = conversations.find(
        (item) => item.id === conversationId
      );
      const messages = conversation?.messages || [];

      const _subject = sendMessage({
        sendedMessages: messages,
        userMessage,
      });
      const subscription = _subject.subscribe({
        next: (message) => {
          set({
            replyingMessage: Object.assign({ conversationId }, message),
          });
        },
        complete: async () => {
          const { replyingMessage } = get();
          if (replyingMessage) {
            await insetToConversationMessages({
              conversionId: conversationId,
              conversationMessage: replyingMessage,
            });
          }
          set({ replyingMessage: undefined, subscription: undefined });
        },
        error: async (error: ConversationRobotMessageType) => {
          await insetToConversationMessages({
            conversionId: conversationId,
            conversationMessage: error,
          });
          set({ replyingMessage: undefined, subscription: undefined });
        },
      });
      set({ subscription: subscription });
    },
  }))
);

const { reply } = useConversationsPropsStore.getState();

export const useConversationListStore = create(
  persist<ConversationListState>(
    (set, get) => ({
      conversations: [],

      setConversations: (conversations) => {
        set({ conversations });
      },
      getRoleConfig: (role) => {
        const { conversationRole } = get();
        return chatSkinConfigByRole[role ?? conversationRole];
      },
      conversationRole: ConversationMessageRoleEnum["normal"],
      create: (payload) => {
        const { title, userMessageParams } = payload;
        const { conversations } = get();
        const userMessage = createUserChatMessage({
          role: userMessageParams.role,
          message: userMessageParams.message,
          channel: userMessageParams.channel,
          sender: ConversationMessageSenderEnum.user,
          extensions: userMessageParams.extensions,
        });
        const createConversion = createConversation({
          title: title,
          entry: ConversationEntryEnum["chat"],
          messages: [],
        });
        const newConversions = produce(conversations, (draft) => {
          draft.push(createConversion);
        });
        set({
          conversations: newConversions,
        });

        reply({
          conversationId: createConversion.id,
          userMessage,
        });
        return createConversion;
      },
      clear: () => {
        set({
          conversations: [],
        });
      },
      insetToConversationMessages: async (payload) => {
        const { conversionId, conversationMessage } = payload;
        const { conversations } = get();
        let message = conversationMessage.message;
        if (conversationMessage.message) {
          if (typeof conversationMessage.message === "string") {
            message = await EncooHttpService.chart.scanText(
              conversationMessage.message
            );
          } else {
            message = await EncooHttpService.chart.scanText(
              (conversationMessage.message.filter(
                (item) => item.type === "text"
              )?.[0]?.["text"] as string) ?? ""
            );
          }
        }
        const newConversationMessage = produce(conversationMessage, (draft) => {
          draft.message = message;
        });
        set({
          conversations: produce(conversations, (draft) => {
            const conversation = draft.find((c) => c.id === conversionId);
            if (conversation) {
              Object.assign(conversation, {
                messages: [
                  ...(conversation?.messages ?? []),
                  newConversationMessage,
                ],
              });
            }
          }),
        });
      },
      addMessage: async (payload) => {
        const { conversionId, userMessageParams } = payload;

        const userMessage = createUserChatMessage({
          role: userMessageParams.role,
          message: userMessageParams.message,
          channel: userMessageParams.channel,

          sender: ConversationMessageSenderEnum.user,
          extensions: userMessageParams.extensions,
        });

        reply({
          conversationId: conversionId,
          userMessage,
        });
      },
      resend: (conversionId: string) => {
        const { conversations } = get();
        const conversation = conversations.find((c) => c.id === conversionId);
        const messages = conversation?.messages ?? [];
        const lastIndex = findLastIndex(
          messages,
          (message) => message.sender === ConversationMessageSenderEnum["user"]
        );
        const message = nth(messages, lastIndex);
        if (message && isUserChatMessage(message)) {
          set({
            conversations: produce(conversations, (draft) => {
              const draftConversation = draft.find(
                (c) => c.id === conversionId
              );
              if (draftConversation && draftConversation?.messages) {
                draftConversation.messages = draftConversation.messages.slice(
                  0,
                  lastIndex
                );
              }
            }),
          });
          reply({
            conversationId: conversionId,
            userMessage: message,
          });
        }
      },
      clearErrorMessage: (conversionId: string) => {
        const { conversations } = get();
        const conversion = conversations.find((c) => c.id === conversionId);
        const hasError = conversion?.messages.some((msg) => msg.error);

        if (!hasError) {
          return;
        }
        const newConversions = produce(conversations, (draft) => {
          const conversion = draft.find((c) => c.id === conversionId);
          if (conversion?.messages) {
            conversion.messages = conversion.messages.filter(
              (msg) => !msg.error
            );
          }
          return draft;
        });
        set({
          conversations: newConversions,
        });
      },
      setConversationRole: (role) => {
        set({
          conversationRole: role,
        });
      },
    }),

    {
      name: "bee-conversations", // unique name
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export function useCurrentConversion(): {
  isExpired: boolean;
  conversation?: Conversation;
} {
  const { conversations } = useConversationListStore((state) => state);
  const { conversationId } = useChartInputStore((state) => state);
  if (conversationId) {
    return {
      isExpired: false,
      conversation: conversations.find((item) => item.id === conversationId),
    };
  }
  const lastConversation = last(conversations);
  const isExpired = lastConversation
    ? Date.now() - lastConversation.lastModifyTime > 1000 * 60 * 60
    : false;

  return {
    isExpired,
    conversation: lastConversation,
  };
}

export const useConversationCheckedStore = create<{
  multiSelectedMessageIds: string[];
  setMultiSelectedMessageIds: (multiSelectedMessageIds: string[]) => void;
  showCheckbox: boolean;
  showShareMask: boolean;
  showSetting: boolean;
  setShowSetting: (showSetting: boolean) => void;
  setShowCheckbox: (showCheckbox: boolean) => void;
  setShowShareMask: (showShareMask: boolean) => void;
}>((set) => ({
  multiSelectedMessageIds: [],
  setMultiSelectedMessageIds: (multiSelectedMessageIds) =>
    set({ multiSelectedMessageIds }),
  showCheckbox: false,
  showShareMask: false,
  showSetting: false,
  setShowCheckbox: (showCheckbox) => set({ showCheckbox }),
  setShowShareMask: (showShareMask) => set({ showShareMask }),
  setShowSetting: (showSetting) => set({ showSetting }),
}));
