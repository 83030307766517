import { catSkin } from "@/containers/Routers/Chart/skins/cat";
import { normalSkin } from "@/containers/Routers/Chart/skins/normal";
import { createSkinByRole } from "@/containers/Routers/Chart/skins/shared";
import { smartSkin } from "@/containers/Routers/Chart/skins/smart";
import { ConversationMessageRoleEnum } from "@/services/models/chart";

export const chatSkinConfigByRole = createSkinByRole({
  [ConversationMessageRoleEnum["normal"]]: normalSkin,
  [ConversationMessageRoleEnum["cat"]]: catSkin,
  [ConversationMessageRoleEnum["smart"]]: smartSkin,
});
export const chatRoleSkinConfigs = Object.values(chatSkinConfigByRole);
export type ChatSkinConfigMapType = typeof chatSkinConfigByRole;
