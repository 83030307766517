/** @jsxImportSource @emotion/react */
import Button from "@/components/Button";
import Dialog from "@/components/Dialog";
import Typography from "@/components/Typography";
import { columnGapUtils } from "@/utils/css";
import { css } from "@emotion/react";
import classNames from "classnames";
import { memo, ReactNode } from "react";

export type ModalProps = {
  className?: string;
  title?: ReactNode;
  isVisible: boolean;
  hasMask?: boolean;
  fullWidth?: boolean;
  icon?: ReactNode;
  children?: ReactNode;
  okText?: string;
  maskClosable?: boolean;
  cancelText?: string;
  onOk?: () => void;
  onCancel?: () => void;
};

export default memo(function Modal(props: ModalProps) {
  const {
    className,
    title,
    hasMask,
    icon,
    children,
    isVisible,
    maskClosable = false,
    cancelText = "取消",
    okText = "确认",
    fullWidth,
    onOk,
    onCancel,
  } = props;
  return (
    <Dialog
      className={className}
      isVisible={isVisible}
      hasMask={hasMask}
      maskClosable={maskClosable}
      fullWidth={fullWidth}
      css={css`
        .MuiDialog-paper {
          padding: 22px 14px;
        }
      `}
    >
      {icon ? (
        <div
          className="absolute"
          css={css`
            left: -40px;
            top: 0px;
            width: 110px;
            height: 80px;
            margin-top: -40px;
            border-radius: 50%;
          `}
        >
          {icon}
        </div>
      ) : null}
      {title ? (
        <div className={classNames("flex-col flex h-12")}>
          <div
            className={classNames("flex items-center justify-center flex-1")}
          >
            <Typography
              type="subTitle-bold"
              color="030718"
              className="truncate"
            >
              {title}
            </Typography>
          </div>
        </div>
      ) : null}
      <div className={"flex flex-col modal-content shrink-0"}>{children}</div>
      <div className="flex-col flex modal-footer">
        <div
          className="flex flex-1 flex-row"
          css={[
            css`
              margin-top: 24px;
              .modal-btn-cancel,
              .modal-btn-ok {
                font-size: 14px;
                border-radius: 20px;
                height: 40px;
              }
            `,
            columnGapUtils(12),
          ]}
        >
          {onCancel ? (
            <Button
              backGroundColor="#f5f6fa"
              textColor="#888C9A"
              className="modal-btn-cancel flex flex-1"
              onClick={onCancel}
            >
              {cancelText}
            </Button>
          ) : null}
          {onOk ? (
            <Button
              css={css`
                background: linear-gradient(270deg, #f6d046 0%, #faea59 100%);
              `}
              textColor="#030718"
              className="modal-btn-ok  flex flex-1"
              onClick={onOk}
            >
              {okText}
            </Button>
          ) : null}
        </div>
      </div>
    </Dialog>
  );
});
