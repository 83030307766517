import { HttpClient } from "@/services/apis/shared";
import { UserProfile, WXConfig } from "@/services/models/user";

export class EncooProfileHttpClient extends HttpClient {
  public async getUserProfile() {
    const { data } = await this.request<UserProfile>({
      method: "GET",
      url: `/v1/profile`,
    });
    return data;
  }

  public async getFollow() {
    const { data } = await this.request<{ subscribedAt: string }>({
      method: "GET",
      url: `/v1/wechat/Subscribtion`,
    });
    return !!data.subscribedAt;
  }

  public async getWxConfig() {
    const url = new URL(window.location.href);
    const hash = url.hash;
    const { data } = await this.request<WXConfig>({
      method: "POST",
      url: `/v1/WechatSecurite/RequestShareSignature`,
      data: { url: url.href.replace(hash, "") },
    });
    return data;
  }
}
