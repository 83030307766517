import { HttpClient } from "@/services/apis/shared";
import { CategoryChatListType } from "@/services/models/category";
import {
  ChartQuota,
  ChatRoleParamMap,
  ConversationChannelEnum,
  ConversationMessageExtensionEnum,
} from "@/services/models/chart";
import {
  FileUpload,
  FileUploadReTurnContent,
} from "@/services/models/chartDrawing";
import { ObjectValues } from "@/types/object";
import { isWebUrl } from "@/utils/url";
import Axios, { AxiosRequestConfig } from "axios";

interface OpenAICompletionsResponseChoice {
  text: string;
  index: number;
  logprobs?: string | null;
  finish_reason?: string | null;
}

interface OpenAICompletionsResponse {
  id: string;
  object: string;
  created: number;
  choices: OpenAICompletionsResponseChoice[];
  model: "text-davinci-003";
}

interface OpenAICompletionsErrorResponse {
  message: string;
  type: "server_error";
  param: null;
  code: null;
}

export enum ChatRequestMessageRoleEnum {
  System = "system",
  User = "user",
  Assistant = "assistant",
}

export type ChatRequestMessageContentType = "text" | "image_url";
export interface ChatImageUrl {
  url: string;
  detail: string;
}
export type ChatRequestMessageContent = {
  type: ChatRequestMessageContentType;
} & {
  [key in ChatRequestMessageContentType]?: string | ChatImageUrl;
};

export interface ChatRequestMessage {
  role: ChatRequestMessageRoleEnum;
  content: string | ChatRequestMessageContent[];
  transform?: {
    type: string;
    payload?: unknown;
  };
}

export type ChatRequestConversationPayload = ObjectValues<ChatRoleParamMap>;

function parseOpenAIValue(value: string): OpenAICompletionsResponse[] {
  return value
    .split("\n\n")
    .map((item) => {
      let v = item;

      if (v.charAt(v.length - 1) === "\n") {
        v = v.substring(0, v.length - 1);
      }
      v = v.replace("data: ", "");
      try {
        if (!v) {
          return undefined;
        }
        const o = JSON.parse(v) as OpenAICompletionsResponse;

        return {
          ...o,
          choices: o.choices.map((ch: { text: string }) => ({
            ...ch,
            text: ch.text,
          })),
        };
      } catch (error) {
        return undefined;
      }
    })
    .filter(Boolean) as OpenAICompletionsResponse[];
}

export class EncooBeeChatHttpClient extends HttpClient {
  public async getChartQuota() {
    const { data } = await this.request<{ data: ChartQuota }>({
      method: "GET",
      url: `/honeybee/chat/quota`,
    });
    return data.data;
  }
  public async getChartQuota4() {
    const { data } = await this.request<{ data: ChartQuota }>({
      method: "GET",
      url: `/honeybee/chat/quota/gpt4`,
    });
    return data.data;
  }

  public async scanText(text: string): Promise<string> {
    const { data } = await this.request<{ filteredContent: string }>({
      method: "POST",
      url: `/honeybee/chat/textscan`,
      data: { content: text },
    });
    return data.filteredContent;
  }
  public async getChatCategory() {
    const { data } = await this.request<CategoryChatListType[]>({
      method: "GET",
      url: `/honeybee/chat/getCustomizedSceneList`,
    });
    return data;
  }
  public async fileUpload(payload: FileUpload) {
    const { fileName, fileSize, contentType } = payload;
    const { data } = await this.request<FileUploadReTurnContent>({
      method: "POST",
      url: `/honeybee/FileUpload`,
      data: { fileName, fileSize, contentType },
    });
    return data;
  }
  public async markerFileUpload(fileId: string) {
    const { data } = await this.request<{ url: string }>({
      method: "PATCH",
      url: `/honeybee/FileUpload/${fileId}`,
    });
    return data;
  }

  public async sendMessage(payload: {
    prompt: ChatRequestMessage[];
    channel?: ConversationChannelEnum;
    extensions?: ConversationMessageExtensionEnum[];
    conversationPayload?: ChatRequestConversationPayload;
    onRead: (text: string) => void | Promise<void>;
    onDone: () => void | Promise<void>;
    onError: (error: Error) => void | Promise<void>;
  }) {
    const {
      prompt,
      extensions,
      conversationPayload,
      channel,
      onRead,
      onDone,
      onError,
    } = payload;
    try {
      const response = await this.fetch({
        method: "POST",
        url: "/honeybee/chat/messages",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: {
          extensions,
          messages: prompt,
          "x-referer-url": window.location.href,
          channel,
          conversationPayload,
        },
      });
      if (response.status !== 200) {
        const text = await response.text();
        const errorResp: OpenAICompletionsErrorResponse = JSON.parse(
          text ?? ""
        );
        onError(new Error(errorResp?.message ?? "An error occurred. "));
        throw errorResp;
      }
      const reader = response.body?.getReader();
      while (reader) {
        const { value, done } = await reader.read();
        if (value) {
          const results = parseOpenAIValue(new TextDecoder().decode(value));
          let text = "";
          for (const result of results) {
            text += result.choices?.[0]?.text ?? "";
          }
          await onRead(text);
        }
        if (done) {
          await onDone();
          break;
        }
      }
    } catch (error) {
      await onError(error as Error);
      throw error;
    }
  }

  public async uploadUris(file: FileUpload) {
    let response: any;
    const { id, multiUploadUri: uploadUris } = await this.fileUpload(file);

    const combineUri = uploadUris.find(
      (uri) => uri.type === "CombineByConsole" || uri.type === "Combine"
    );
    const partUris = uploadUris.filter(
      (uri) => uri.type === "MultiPart" || uri.type === "SingleFile"
    );
    try {
      const partResponse = await Promise.all(
        partUris.map(async (part) => {
          const requestConfig: AxiosRequestConfig = {
            method: part.verb,
            url: part.uri,
            headers: {},
          };
          switch (part.type) {
            case "SingleFile":
              requestConfig.data = file.fileContent;
              requestConfig.headers = part.headers;
              break;
            case "MultiPart":
              requestConfig.headers = Object.assign(
                requestConfig.headers ?? {},
                part.headers ?? {},
                {
                  "Content-Type": "application/octet-stream",
                }
              );
              requestConfig.data = file.fileContent.slice(
                part.partContent.startPos - 1,
                part.partContent.startPos - 1 + part.partContent.length
              );
              break;
          }
          const response = await Axios(requestConfig);

          const partData: [string, string][] = [];
          part.partResponseDatas?.forEach((data: any) => {
            if (data.fromType === "Header") {
              partData.push([
                data.name,
                response.headers[data.fromValue] ??
                  response.headers[data.fromValue.toLocaleLowerCase()],
              ]);
            }
          });
          return partData;
        })
      );
      if (combineUri) {
        response = this.request({
          method: combineUri.verb,
          url: isWebUrl(combineUri.uri)
            ? combineUri.uri
            : `${window._setting.api.baseUrl}/${combineUri.uri}`,
          headers: {
            "content-type":
              combineUri?.headers?.contentType ?? "application/json",
          },
          data: Object.assign(
            Object.fromEntries(partResponse.flat()),
            combineUri.combineByConsoleContent
          ),
        });
      }
      if (partResponse || response) {
        return id;
      }
    } catch (error) {
      throw error;
    }
  }
}
