import { Dialog as MuiDialog } from "@mui/material";
import { memo, MouseEvent, ReactNode, useCallback } from "react";

export type DialogProps = {
  className?: string;
  children?: ReactNode;
  isVisible?: boolean;
  hasMask?: boolean;
  maskClosable?: boolean;
  fullWidth?: boolean;
  onClose?: (event: MouseEvent<Element>) => void;
};

export default memo(function Dialog(props: DialogProps) {
  const {
    className,
    children,
    isVisible = false,
    hasMask = true,
    maskClosable = false,
    fullWidth,
    onClose,
  } = props;

  const handleClose = useCallback(
    (event: MouseEvent) => {
      if (!maskClosable) {
        return;
      }
      onClose?.(event);
    },
    [maskClosable, onClose]
  );

  return (
    <MuiDialog
      className={className}
      open={isVisible}
      hideBackdrop={!hasMask}
      fullWidth={fullWidth}
      maxWidth={false}
      onClose={handleClose}
    >
      {children}
    </MuiDialog>
  );
});
