import { EncooHttpService } from "@/services/EncooHttpService";
import {
  ConversationMessageBaseType,
  ConversationMessageType,
} from "@/services/models/chart";
import { ChartHistory } from "@/services/models/chatHistory";
import { create } from "zustand";

export interface ChartHistoryStoreState {
  solitaireMessage: ConversationMessageBaseType[];
  setSolitaireMessage: (
    solitaireMessage: ConversationMessageBaseType[]
  ) => void;
  createHistory: (history?: ConversationMessageType[]) => Promise<ChartHistory>;
  getHistory: (historyId: string) => void;
}

export const useChartHistoryStore = create<ChartHistoryStoreState>(
  (set, get) => ({
    solitaireMessage: [],
    setSolitaireMessage: (solitaireMessage) => {
      set({ solitaireMessage });
    },
    createHistory: async (history) => {
      return await EncooHttpService.history.createHistory({
        messages: history,
      });
    },
    getHistory: async (historyId) => {
      const { setSolitaireMessage } = get();
      const messages = (await EncooHttpService.history.getHistory(historyId))
        .context.messages as ConversationMessageBaseType[];
      setSolitaireMessage(messages);
    },
  })
);
