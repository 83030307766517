import { createMigration } from "@/containers/Migrations";
import { TaskStorage } from "@/containers/Tasks";
import { get } from "lodash-es";

export const MagicMigration = createMigration({
  description: "chatIntroduce 数据放到taskStore里",
  runCondition: () => {
    return (
      TaskStorage.getItem("magicIntroduce") === undefined &&
      get(
        JSON.parse(localStorage.getItem("bee-chatIntroduce") || "{}"),
        "state.read"
      )
    );
  },
  onRun: async () => {
    localStorage.removeItem("bee-chatIntroduce");
    return TaskStorage.setItem("magicIntroduce", true);
  },
});
