import { EncooAuthHttpClient } from "@/services/apis/EncooAuthHttpClient";
import { EncooBeeChatHttpClient } from "@/services/apis/EncooBeeChatHttpClient";
import { EncooOrderHttpClient } from "@/services/apis/EncooOrderHttpClient";
import { EncooOrderPaymentHttpClient } from "@/services/apis/EncooOrderPaymentHttpClient";
import { EncooProductHttpClient } from "@/services/apis/EncooProductHttpClient";
import { EncooProfileHttpClient } from "@/services/apis/EncooProfileHttpClient";
import { EncooUserServiceHttpClient } from "@/services/apis/EncooUserServiceHttpClient";
import { getHttpClientModel } from "@/services/apis/shared";

import { EncooHttpServiceBase } from "@/services/EncooHttpServiceBase";
import { EncooChartHistoryServiceHttpClient } from "@/services/apis/EncooChartHistoryServiceHttpClient";
import { EncooDrawingHttpClient } from "@/services/apis/EncooDrawingServiceHttpClient";
import { EncooQuotaHttpClient } from "@/services/apis/EncooQuotaHttpClient";
import { EncooShareHttpClient } from "@/services/apis/EncooShareHttpClient";

export class EncooHttpServiceContainer extends EncooHttpServiceBase {
  public get auth() {
    return getHttpClientModel(EncooAuthHttpClient, [
      this.getAccessToken,
      this.getHoneyBeeBaseUrl,
      this.applyErrorMiddle,
    ]);
  }
  public get profile() {
    return getHttpClientModel(EncooProfileHttpClient, [
      this.getAccessToken,
      this.getHoneyBeeBaseUrl,
      this.applyErrorMiddle,
      this.setSentryUserFromProfile,
      this.setSentryUserFromWx,
    ]);
  }

  public get chart() {
    return getHttpClientModel(EncooBeeChatHttpClient, [
      this.getAccessToken,
      this.getBeeBaseUrl,
      this.applyErrorMiddle,
    ]);
  }
  public get userService() {
    return getHttpClientModel(EncooUserServiceHttpClient, [
      this.getAccessToken,
      this.getBeeBaseUrl,
      this.applyErrorMiddle,
    ]);
  }
  public get order() {
    return getHttpClientModel(EncooOrderHttpClient, [
      this.getAccessToken,
      this.getBeeBaseUrl,
      this.applyErrorMiddle,
    ]);
  }
  public get product() {
    return getHttpClientModel(EncooProductHttpClient, [
      this.getAccessToken,
      this.getBeeBaseUrl,
      this.applyErrorMiddle,
    ]);
  }
  public get orderPayment() {
    return getHttpClientModel(EncooOrderPaymentHttpClient, [
      this.getAccessToken,
      this.getBeeBaseUrl,
      this.applyErrorMiddle,
    ]);
  }
  public get quota() {
    return getHttpClientModel(EncooQuotaHttpClient, [
      this.getAccessToken,
      this.getBeeBaseUrl,
      this.applyErrorMiddle,
    ]);
  }
  public get share() {
    return getHttpClientModel(EncooShareHttpClient, [
      this.getAccessToken,
      this.getBeeBaseUrl,
      this.applyErrorMiddle,
    ]);
  }
  public get history() {
    return getHttpClientModel(EncooChartHistoryServiceHttpClient, [
      this.getAccessToken,
      this.getBeeBaseUrl,
      this.applyErrorMiddle,
    ]);
  }
  public get drawing() {
    return getHttpClientModel(EncooDrawingHttpClient, [
      this.getAccessToken,
      this.getBeeBaseUrl,
      this.applyErrorMiddle,
    ]);
  }
}
export const EncooHttpService = new EncooHttpServiceContainer();
