import { useLoading } from "@/containers/Providers/Loading";
import { useMessage } from "@/containers/Providers/Message";
import { RoutePathUnion } from "@/containers/Routers/configs";
import { OrderStatusEnum } from "@/services/models/order";
import { wxShareConfig } from "@/shared/config/page";
import { useChartDrawingStore } from "@/store/chartDrawing";
import { useChartQuotaStore } from "@/store/chartQuota";
import { useGiftExChangeStateStore } from "@/store/giftExchange";
import { useLoginUserStore } from "@/store/loginUser";
import { useOrderStore } from "@/store/order";
import { usePaymentStore } from "@/store/payment";
import { useProductStore } from "@/store/product";
import { useUserServiceStore } from "@/store/userService";
import { queryStringify } from "@/utils/obj";
import { useCallback } from "react";
import wx, { MenuCallbacks } from "weixin-js-sdk";

export function usePay() {
  const messageConfirm = useMessage();
  const { onClose, onOpen } = useLoading();
  const { createOrdersClient } = useOrderStore((state) => state);
  const { createPrePaymentClient, getPrePaymentOrderClient } = usePaymentStore(
    (state) => state
  );
  const { refreshUserServicersClient } = useUserServiceStore((state) => state);
  const { refreshProductsClient } = useProductStore((state) => state);
  const { refresh } = useChartQuotaStore((state) => state);
  const { refreshOrdersClient } = useOrderStore((state) => state);
  const { setIsExchange } = useGiftExChangeStateStore((state) => state);

  const startCheckOrder = useCallback(
    (productId: string, orderId: string, giftExchange?: boolean) => {
      let maxCount = 10;
      messageConfirm("正在支付中！");
      const checkOrder = (orderId: string) => {
        maxCount--;
        getPrePaymentOrderClient(orderId).then((item) => {
          if (item.status === OrderStatusEnum["completed"]) {
            messageConfirm("支付成功");
            if (
              productId === "00000000-0000-0000-0001-000000000005" ||
              productId === "00000000-0000-0000-0001-000000000006" ||
              productId === "00000000-0000-0000-0001-000000000007"
            ) {
              messageConfirm("您已成功发愿，愿力集结中~ 感谢支持😘");
            }
            onClose();
            refreshUserServicersClient();
            refreshOrdersClient();
            refreshProductsClient();
            refresh();
            if (giftExchange) {
              messageConfirm("兑换成功");
              setIsExchange(true);
            }
          } else if (
            [
              OrderStatusEnum["canceled"],
              OrderStatusEnum["refunding"],
              OrderStatusEnum["refunded"],
              OrderStatusEnum["refundFailed"],
              OrderStatusEnum["timeout"],
            ].includes(item.status)
          ) {
            messageConfirm("支付失败");
            onClose();
          } else {
            if (maxCount) {
              setTimeout(() => {
                checkOrder(orderId);
              }, 1000 * 1.5);
            } else {
              onClose();
            }
          }
        });
      };
      checkOrder(orderId);
    },
    [
      messageConfirm,
      getPrePaymentOrderClient,
      onClose,
      refreshUserServicersClient,
      refreshOrdersClient,
      refreshProductsClient,
      refresh,
      setIsExchange,
    ]
  );
  return useCallback(
    async (productId: string, giftExchange?: boolean) => {
      try {
        onOpen();
        const order = await createOrdersClient({
          productId: productId,
          quantity: 1,
        });
        const prePayment = await createPrePaymentClient(order.id);
        wx.checkJsApi({
          jsApiList: ["chooseWXPay"],
          success: () => {
            onClose();
            wx.chooseWXPay({
              timestamp: prePayment.timestamp,
              nonceStr: prePayment.nonce,
              package: prePayment.package,
              signType: prePayment.signType,
              paySign: prePayment.paySign,
              success: () => {
                startCheckOrder(productId, order.id, giftExchange);
              },
              fail: () => {
                messageConfirm("支付失败");
                refreshOrdersClient();
              },
              cancel: () => {
                messageConfirm("支付取消");
                refreshOrdersClient();
              },
            });
          },
        });
      } finally {
        onClose();
      }
    },
    [
      createOrdersClient,
      createPrePaymentClient,
      messageConfirm,
      onClose,
      onOpen,
      refreshOrdersClient,
      startCheckOrder,
    ]
  );
}

export function shareWx(
  path: string | RoutePathUnion,
  props?: {
    params?: Record<string, string>;
    title?: string;
    desc?: string;
  } & MenuCallbacks
) {
  const defaultShare = wxShareConfig.chart();
  const {
    title = defaultShare.title,
    desc = defaultShare.desc,
    params = {},
    ...otherProps
  } = props ?? {};
  const userProfile = useLoginUserStore.getState().userProfile;
  wx.ready(() => {
    const host = window.location.origin;
    const userParams = {
      scode: userProfile?.id,
    };
    const link = `${host}/${path.replace(/^\/+/gi, "")}?${queryStringify(
      Object.assign(userParams, params)
    )}`;
    const imgUrl = `${host}/share.png`;
    const options = {
      title,
      desc,
      link,
      imgUrl,
      ...otherProps,
    };
    wx.updateAppMessageShareData(options);
    wx.updateTimelineShareData(options);
  });
}

export function useTriggrtShare() {
  return useCallback(shareWx, []);
}

export function useChooseImage() {
  const { onClose, onOpen } = useLoading();
  const { setIsLoading, setSelectedImage, getlocalData } = useChartDrawingStore(
    (state) => state
  );

  const getImgData = useCallback(
    (index: number, length: number, localIds: string[]) => {
      if (index >= localIds.length) return;
      try {
        wx.getLocalImgData({
          localId: localIds[index],
          success: async (res) => {
            const localData = res.localData;
            const imgurls = await getlocalData(localIds[index], localData);
            // 上传下一张
            if (imgurls.length > 0 && imgurls.length - 1 === index) {
              index++;
              if (index < length) {
                getImgData(index, length, localIds);
              }
            }

            setIsLoading(false);
          },
          fail: (err) => {
            console.log("getLocalImgDataError", err);
          },
        });
      } finally {
      }
    },
    [getlocalData, setIsLoading]
  );

  return useCallback(() => {
    try {
      onOpen();
      wx.checkJsApi({
        jsApiList: ["chooseImage"],
        success: () => {
          onClose();
          wx.chooseImage({
            count: 1,
            success: (res) => {
              setSelectedImage([]);
              setIsLoading(true);
              let index = 0;
              const length = res.localIds.length;
              // 关键在这里，上一张上传完了再获取下一张
              if (index < length) {
                getImgData(index, length, res.localIds);
              }
            },
            fail: (err) => {
              console.log("chooseImageError", err);
            },
          });
        },
      });
    } finally {
      onClose();
    }
  }, [getImgData, onClose, onOpen, setIsLoading, setSelectedImage]);
}
