/** @jsxImportSource @emotion/react */
import { createGuard } from "@/containers/Guards";
import { useNavigateToAuthentication } from "@/containers/Guards/_hooks";
import { useAuthStore } from "@/store/auth";
import { memo, useEffect } from "react";
export const AuthNavigateView = memo(() => {
  const { setEntry } = useAuthStore((state) => state);
  const navigateToAuthentication = useNavigateToAuthentication();
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    navigateToAuthentication(Object.fromEntries(searchParams));
  }, [navigateToAuthentication, setEntry]);

  return null;
});

export const AuthNavigateGuard = createGuard({
  description: "微信授权scode 换取code",
  runCondition: function () {
    const { tokenStatus } = useAuthStore.getState();
    const searchParams = new URLSearchParams(window.location.search);
    return (
      Boolean(searchParams.get("scode") && !searchParams.get("code")) ||
      (!searchParams.get("code") && tokenStatus === "unset")
    );
  },
  render: () => <AuthNavigateView />,
  subscribe: (runGuard) => {
    useAuthStore.subscribe(() => runGuard());
  },
});
