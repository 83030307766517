import { ObjectType, ReadonlyUnion } from "@/types/shared";
import { MapFromArray, MapFromEntries } from "@/types/utils";

export function filterNonNullish<T>(arr: (T | null | undefined)[]): T[] {
  return arr.filter((e) => e != null) as T[];
}

export function getItem<T>(arr: T[], index: number): T | undefined {
  return index < arr.length ? arr[index] : undefined;
}

export function last<T>(arr: T[]): T | undefined {
  return getItem(arr, arr.length - 1);
}

export function chained(actions: ((...args: any[]) => any)[]) {
  return function (input: any) {
    return actions.reduce(function (input, action) {
      return action(input);
    }, input);
  };
}

export function createMapFromArray<
  T extends ReadonlyUnion<ObjectType[]> | ReadonlyUnion<[...Array<ObjectType>]>,
  K extends keyof T[number]
>(data: T, key: K) {
  return Object.fromEntries(
    data.map((item) => [item[key as keyof typeof item], item])
  ) as MapFromArray<T, K>;
}

export function createMapFromEntries<
  T extends
    | ReadonlyUnion<[string, unknown][]>
    | ReadonlyUnion<[...Array<[string, unknown]>]>
>(data: T) {
  return Object.fromEntries(data) as MapFromEntries<T>;
}
