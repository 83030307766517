/** @jsxImportSource @emotion/react */
import { useConfirm } from "@/containers/Providers/Confirm";
import { useConfirmStore } from "@/store/confirm";
import { memo, useEffect } from "react";

export const MessageConfirm = memo(() => {
  const confirm = useConfirm();
  const { config, setConfig } = useConfirmStore((state) => state);
  useEffect(() => {
    if (config) {
      const {
        content,
        onOk,
        okText,
        cancelText,
        okVisible,
        cancelVisible,
        onCancel,
      } = config;
      confirm({
        content: content,
        onOk,
        okText,
        okVisible,
        cancelVisible,
        cancelText,
        onCancel: () => {
          setConfig();
          onCancel?.();
        },
      });
    }
  }, [config, confirm, setConfig]);
  return null;
});
