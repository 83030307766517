import { createMigration } from "@/containers/Migrations";
import { usePaymentStore } from "@/store/payment";
import { AppStorage } from "@/store/storageStore";

export const PaymentInitMigration = createMigration({
  description: "Payment 页面初始化",
  runCondition: () => true,
  onRun: async () => {
    usePaymentStore.setState({
      expiryTime: AppStorage.getItem("expiryTime") ?? "2023-08-15",
    });
    return;
  },
});
