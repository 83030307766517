import { EncooHttpService } from "@/services/EncooHttpService";
import { PayOrder } from "@/services/models/order";
import { PrePaymentOrder } from "@/services/models/payment";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

export interface PaymentStoreState {
  expiryTime: string;
  setExpiryTime: (expiryTime: string) => void;
  createPrePaymentClient: (orderId: string) => Promise<PrePaymentOrder>;
  getPrePaymentOrderClient: (orderId: string) => Promise<PayOrder>;
}

export const usePaymentStore = create(
  subscribeWithSelector<PaymentStoreState>((set, get) => ({
    expiryTime: "",
    setExpiryTime: (expiryTime) => {
      set({ expiryTime });
    },
    createPrePaymentClient: async (orderId) => {
      const orders = await EncooHttpService.orderPayment.createPrePaymentOrder(
        orderId
      );
      return orders;
    },
    getPrePaymentOrderClient: async (orderId) => {
      const orders = await EncooHttpService.orderPayment.getPrevPaymentOrder(
        orderId
      );
      return orders;
    },
  }))
);
