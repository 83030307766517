import { ChatRequestMessageContent } from "@/services/apis/EncooBeeChatHttpClient";
import { ObjectValues } from "@/types/object";
import { NumberDateType } from "@/types/shared";
import { Exclusive } from "@/types/utils";
export enum ConversationMessageSenderEnum {
  "user" = "user",
  "robot" = "robot",
}
export enum ConversationChannelEnum {
  "gpt3" = "gpt3",
  "gpt4" = "gpt4",
  "claude_3_haiku" = "claude_3_haiku",
  "claude_3_sonnet" = "claude_3_sonnet",
}
export enum ConversationMessageRoleEnum {
  "cat" = "cat",
  "normal" = "normal",
  "smart" = "smart",
}
export enum ConversationMessageExtensionEnum {
  "longText" = "longText",
  "network" = "network",
  "highQualityImage" = "highQualityImage",
}
export enum ConversationEntryEnum {
  "chat" = "chat", //以后为文心 千问 等 做入口
}
export enum ConversationMessageGroupEnum {
  "group" = "group",
}
export enum ConversationEnum {
  "conversation" = "conversation",
}
export interface ConversationReplyingMessage
  extends ConversationRobotMessageType {
  conversationId: string;
}

interface ConversationBaseMessage {
  id: string;
  message: string | ChatRequestMessageContent[];
  channel: ConversationChannelEnum;
  role: ConversationMessageRoleEnum;
  extensions?: ConversationMessageExtensionEnum[];
  createTime: NumberDateType;
  deleteTime?: NumberDateType;
}

export type ConversationMessageTransformType = "magic";

export interface ConversationMessageTransform {
  type: ConversationMessageTransformType;
  payload?: unknown;
}

export function createConversationMessageMagicTransform(): ConversationMessageTransform {
  return { type: "magic" };
}

export interface ConversationUserMessageType extends ConversationBaseMessage {
  sender: ConversationMessageSenderEnum.user;
  transform?: ConversationMessageTransform;
  nickName: string;
  picture: string;
}
export interface ConversationRobotMessageType
  extends Omit<ConversationBaseMessage, "message"> {
  message: string;
  sender: ConversationMessageSenderEnum.robot;
  questionId: string;
  error?: string;
  skip?: boolean;
}

export type ConversationMessageBaseGroupType = {
  type: ConversationMessageGroupEnum;
  id: string;
  title?: string;
  messages: ConversationMessageBaseType[];
  deleteTime?: NumberDateType;
  createTime: NumberDateType;
};
export interface ConversationMessageShareGroupType
  extends ConversationMessageBaseGroupType {
  entry: "share";
}

export type ConversationMessageGroupType = ConversationMessageShareGroupType;

export type ConversationMessageBaseType = Exclusive<
  ConversationUserMessageType | ConversationRobotMessageType
>;

export type ConversationMessageType = Exclusive<
  ConversationMessageBaseType | ConversationMessageGroupType
>;

export interface Conversation {
  id: string;
  title: string;
  createTime: NumberDateType;
  type: ConversationEnum;
  lastModifyTime: NumberDateType;
  deleteTime?: NumberDateType;
  messages: ConversationMessageType[];
}

export interface ChartQuota {
  max: number;
  used: number;
}

export type SmartRoleParam = {
  role: ConversationMessageRoleEnum.smart;
  payload:
    | {
        mode: "persons";
        items: string[];
      }
    | { mode: "prompt"; prompt: string };
};

export type CatRoleParam = {
  role: ConversationMessageRoleEnum.cat;
};

export type ChatRoleParamMap = {
  [ConversationMessageRoleEnum.cat]: CatRoleParam;
  [ConversationMessageRoleEnum.smart]: SmartRoleParam;
  [ConversationMessageRoleEnum.normal]: undefined;
};

export type ChatRoleParamUnion = ObjectValues<ChatRoleParamMap>;

export enum ChatChannelEnum {
  "gpt3" = "GPT_35",
  "gpt4" = "GPT_40",
  "image" = "Image",
}
