/** @jsxImportSource @emotion/react */
import { GuardStateType } from "@/containers/Guards";
import { DisclaimerGuard } from "@/containers/Guards/Disclaimer";
import { SubscriptionErrorGuard } from "@/containers/Guards/SubscriptionError";
import { SubscriptionLoadingGuard } from "@/containers/Guards/SubscriptionLoading";
import { SubscriptionViewGuard } from "@/containers/Guards/SubscriptionView";
import { MigrationStateType } from "@/containers/Migrations";
import { ChartStoreMigration } from "@/containers/Migrations/chartStore";
import { ConversionMigration } from "@/containers/Migrations/conversion";
import { DrawingInitMigration } from "@/containers/Migrations/drawingInit";
import { MagicMigration } from "@/containers/Migrations/magic";
import { PaymentInitMigration } from "@/containers/Migrations/paymentInit";
import { SolitaireInitMigration } from "@/containers/Migrations/solitaireInit";
import { TaskStateType } from "@/containers/Tasks";
import { DrawingTask } from "@/containers/Tasks/Drawing";
import { MagicTask } from "@/containers/Tasks/IntroduceMask";
import { PromotionalTask } from "@/containers/Tasks/Promotional";
import { TemporaryTask } from "@/containers/Tasks/Temporary";
import { createMapFromArray } from "@/utils/array";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { lazy } from "react";
import { Replace } from "typescript-lodash/lib/string";

export type routerConfigType<TPath extends string, TKey extends string> = {
  key: TKey;
  path: TPath;
  name?: string;
  description?: string;
  element: React.LazyExoticComponent<
    React.MemoExoticComponent<() => EmotionJSX.Element | null>
  >;
  autoTasks?: TaskStateType[];
  guards?: GuardStateType[];
  migrations?: MigrationStateType[];
  children?: routerConfigType<TPath, TKey>[];
};

export function createRouterConfig<
  const T extends string,
  const K extends string
>(config: routerConfigType<T, K>): routerConfigType<T, K> {
  return config;
}
export const routerConfigs = [
  createRouterConfig({
    key: "giftExchange",
    path: "/giftExchange",
    name: "礼品兑换卡",
    description: "礼品兑换卡",
    element: lazy(() => import("@/containers/Routers/Exchange")),
  }),
  createRouterConfig({
    key: "drawing",
    path: "/drawing",
    name: "AI专业生图",
    description: "你的AI设计师，文字生图",
    element: lazy(() => import("@/containers/Routers/Drawing")),
  }),
  createRouterConfig({
    key: "category",
    path: "/category",
    name: "工具箱",
    description: "快捷工具箱、实用Prompt集合",
    element: lazy(() => import("@/containers/Routers/Category")),
  }),
  createRouterConfig({
    key: "specificCategory",
    path: "/category/:id",
    element: lazy(() => import("@/containers/Routers/Category/ChatItem")),
  }),
  createRouterConfig({
    key: "payment",
    path: "/payment",
    name: "我的订阅",
    description: "个人/VIP订阅计划 & 额度购买",
    migrations: [PaymentInitMigration],
    element: lazy(() => import("@/containers/Routers/Payment")),
  }),
  createRouterConfig({
    key: "orders",
    name: "我的订单",
    path: "/orders",
    element: lazy(() => import("@/containers/Routers/Order")),
  }),
  createRouterConfig({
    key: "specificOrders",
    path: "/orders/:id",
    element: lazy(() => import("@/containers/Routers/Order/OrderItem")),
  }),
  createRouterConfig({
    key: "share",
    path: "/share",
    name: "分享中心",
    description: "邀请兑换免费4o & 4o mini-会话额度",
    element: lazy(() => import("@/containers/Routers/Share")),
  }),
  createRouterConfig({
    key: "conversion",
    path: "/conversion",
    name: "兑换中心",
    element: lazy(() => import("@/containers/Routers/Conversion")),
  }),
  createRouterConfig({
    key: "solitaire",
    path: "/solitaire/:id",
    element: lazy(() => import("@/containers/Routers/Solitaire")),
    migrations: [SolitaireInitMigration],
    guards: [
      SubscriptionErrorGuard,
      SubscriptionLoadingGuard,
      SubscriptionViewGuard,
    ],
  }),
  createRouterConfig({
    key: "chart",
    path: "/*",
    element: lazy(() => import("@/containers/Routers/Chart")),
    autoTasks: [
      TemporaryTask,
      MagicTask,
      // PCIntroduceTask,
      PromotionalTask,
      DrawingTask,
    ],
    migrations: [
      MagicMigration,
      ConversionMigration,
      ChartStoreMigration,
      DrawingInitMigration,
    ],
    guards: [
      SubscriptionErrorGuard,
      SubscriptionLoadingGuard,
      SubscriptionViewGuard,
      DisclaimerGuard,
    ],
  }),
  createRouterConfig({
    key: "privateDev",
    path: "/privateDev",
    description: "开发者",
    element: lazy(() => import("@/containers/Routers/PrivateDev")),
  }),
] as const;

export const routerByKey = createMapFromArray(routerConfigs, "key");
export type routerKeyUnion = (typeof routerConfigs)[number]["key"];
export type RoutePathUnion =
  | Replace<(typeof routerConfigs)[number]["path"], ":id", string>
  | "/"
  | "/index.html";
