import { MigrationStorage, createMigration } from "@/containers/Migrations";
import { ConversationEnum } from "@/services/models/chart";
import { useConversationListStore } from "@/store/chart";
import { isUserChatMessage } from "@/store/chartHelper";
import { useLoginUserStore } from "@/store/loginUser";
import produce from "immer";

const updateVersion = "0.0.1";
export const ConversionMigration = createMigration({
  description: "批量给用户conversions 添加图片和nickname ",
  runCondition: () => {
    const storeVersion = MigrationStorage.getItem("conversionVersion") ?? "";
    return (
      storeVersion < updateVersion &&
      Boolean(useLoginUserStore.getState().userProfile)
    );
  },
  onRun: async () => {
    const conversations = useConversationListStore.getState().conversations;
    const newConversations = produce(conversations, (draftConversations) => {
      const userProfile = useLoginUserStore.getState().userProfile!;
      return draftConversations.forEach((conversation) => {
        conversation.type = ConversationEnum["conversation"];
        conversation.messages.forEach((message) => {
          if (isUserChatMessage(message)) {
            Object.assign(message, {
              nickName: userProfile.nickName,
              picture: userProfile.picture,
            });
          }
        });
      });
    });
    useConversationListStore.setState({
      conversations: newConversations,
    });
    MigrationStorage.setItem("conversionVersion", updateVersion);
    return;
  },
});
