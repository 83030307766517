/** @jsxImportSource @emotion/react */
import { Basic } from "@/containers/Routers/Chart/skins/normal/_internal";
import { createSkinConfig } from "@/containers/Routers/Chart/skins/shared";
import { Setting } from "@/containers/Routers/Chart/skins/smart/Setting";
import { ConversationMessageRoleEnum } from "@/services/models/chart";
import { ReactComponent as IconSend } from "@assets/img/icon-normalSend.svg";
import SmartCat from "@assets/img/skin/smart.png";
export const smartSkin = createSkinConfig({
  type: ConversationMessageRoleEnum["smart"],
  name: "五仁智囊团",
  description: "五位古今中外大师为您出谋献策，选择困难就问它！",
  icon: IconSend,
  skinElementContainer: {
    basic: Basic,
  },
  settingElementContainer: {
    basic: Setting,
  },
  avatar: () => <img className="w-full h-full" src={SmartCat} alt="" />,
});
