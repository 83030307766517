import { createMigration } from "@/containers/Migrations";
import { shareWx } from "@/hooks/wx";
import { wxShareConfig } from "@/shared/config/page";
import { useChartHistoryStore } from "@/store/chartHistory";
import { useLoginUserStore } from "@/store/loginUser";

export const SolitaireInitMigration = createMigration({
  description: "Solitaire 页面初始化",
  runCondition: () => true,
  onRun: async () => {
    useChartHistoryStore.setState({
      solitaireMessage: [],
    });
    const nickName = useLoginUserStore.getState().userProfile?.nickName;
    shareWx(window.location.pathname, wxShareConfig.solitaire({ nickName }));
    return;
  },
});
