import { HttpClient } from "@/services/apis/shared";
import { BeeToken } from "@/services/models/user";

export class EncooAuthHttpClient extends HttpClient {
  public async wechatLogin(code: string) {
    const { data } = await this.request<BeeToken>({
      method: "POST",
      url: `/connect/token`,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: {
        code,
        provider: "WechatFollowing",
        grant_type: "provider",
        client_id: "Honeybee",
      },
    });
    return data;
  }
}
