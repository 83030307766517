import { ConfirmProvider } from "@/containers/Providers/Confirm";
import { DrawerProvider } from "@/containers/Providers/Drawer";
import { LoadingProvider } from "@/containers/Providers/Loading";
import { MaskProvider } from "@/containers/Providers/Mask";
import { MessageProvider } from "@/containers/Providers/Message";
import { NotificationProvider } from "@/containers/Providers/Notification";
import EncooBeeRouters from "@/containers/Routers";
import { MessageConfirm } from "@/containers/Routers/_internal/MessageConfirm";
import { MessageNotification } from "@/containers/Routers/_internal/MessageNotification";
import { mainTheme } from "@/shared/themes/main";
import "@/styles/global.css";
import { ThemeProvider } from "@mui/material/styles";
import { memo, useMemo } from "react";

const App = memo(function () {
  const theme = useMemo(() => mainTheme, []);

  return (
    <ThemeProvider theme={theme}>
      <LoadingProvider>
        <MaskProvider>
          <NotificationProvider>
            <ConfirmProvider>
              <MessageProvider>
                <DrawerProvider>
                  <MessageNotification />
                  <MessageConfirm />
                  <EncooBeeRouters />
                </DrawerProvider>
              </MessageProvider>
            </ConfirmProvider>
          </NotificationProvider>
        </MaskProvider>
      </LoadingProvider>
    </ThemeProvider>
  );
});

export default App;
