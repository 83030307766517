export type nodeEnv = "production" | "development" | undefined;
export const appConfig = {
  get platform() {
    const ua = window.navigator.userAgent.toLowerCase();
    const isMobile = ua.includes("mobile");
    const isAndroid = ua.includes("android");
    const isIPad = ua.includes("ipad");
    const isIPod = ua.includes("ipod");
    const isIPhone = ua.includes("iphone");
    const isWeixin = ua.includes("micromessenger");
    const isIOS = isIPad || isIPhone || isIPod;
    return { isMobile, isAndroid, isIOS, isIPad, isIPhone, isIPod, isWeixin };
  },
  get env() {
    return process.env.NODE_ENV as nodeEnv;
  },
  get pcUrl() {
    return "https://chat.beezy.cool/pc";
  },
};
