import {
  HttpClientMiddleware,
  isAxiosError,
  isFeatchResponse,
} from "@/services/apis/shared";
import { AxiosResponse, Method } from "axios";

export type NetErrorResponse = {
  message: string;
  errorCode: number;
  requestId: string;
  timestamp: string;
};

type MiddlewareErrorMessage = {
  url: string;
  status: number;
  statusText: string;
  method: Method | string;
  responseData: NetErrorResponse | {};
  requestData: unknown;
  requestParams?: unknown;
};

export const createErrorMiddleware = (
  onError: (err: MiddlewareErrorMessage) => void
): HttpClientMiddleware => {
  return async (config, next) => {
    try {
      const res = (await next(config)) as Response | AxiosResponse;
      if (res.status >= 400 && isFeatchResponse(res)) {
        const data = await res.clone().json();
        onError({
          url: res.url,
          requestData: config.data,
          requestParams: config.params,
          statusText: res.statusText,
          status: res.status,
          method: config.method!,
          responseData: data,
        });
      }
      return res;
    } catch (err) {
      if (isAxiosError(err)) {
        onError({
          url: err.request.responseURL,
          requestData: config.data,
          requestParams: config.params,
          status: err.request.status,
          statusText: err.request.statusText,
          method: config.method!,
          responseData: err.response?.data!,
        });
      }
      throw err;
    }
  };
};

export function isCustomErrorResponse(res: {}): res is NetErrorResponse {
  return "errorCode" in res && "message" in res;
}
