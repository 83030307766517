import {
  ConversationChannelEnum,
  ConversationMessageRoleEnum,
  ConversationMessageSenderEnum,
  ConversationUserMessageType,
  createConversationMessageMagicTransform,
} from "@/services/models/chart";
import { useChartHelperStore } from "@/store/chartHelper";
import { create } from "zustand";
import { createUserChatMessage } from "./chartHelper";

export interface ChartInputStoreState {
  text: string;
  conversationId?: string;
  channel: ConversationChannelEnum;
  selectedValue: string;
  isMagicSending: boolean;
  sendMagic: () => ConversationUserMessageType;
  setConversationId: (selected: string) => void;
  setChannel: (value: ConversationChannelEnum) => void;
  setText: (value: string) => void;
  setSelectedValue: (value: string) => void;
}

export const useChartInputStore = create<ChartInputStoreState>((set, get) => ({
  text: "",
  conversationId: "",
  channel: ConversationChannelEnum["gpt3"],
  selectedValue: ConversationChannelEnum["gpt3"],
  setConversationId: (conversionId: string) => {
    set({
      conversationId: conversionId,
    });
  },
  setText: (text) => {
    set({ text });
  },
  setChannel: (value) => {
    set({ channel: value });
  },
  setSelectedValue: (value) => set({ selectedValue: value }),
  isMagicSending: false,
  sendMagic: () => {
    const { setText, text } = get();
    const { sendMessage } = useChartHelperStore.getState();

    const userMessage = createUserChatMessage({
      channel: ConversationChannelEnum["gpt3"],
      role: ConversationMessageRoleEnum.normal, // 魔法棒只需普通模式
      message: text,
      transform: createConversationMessageMagicTransform(),
      sender: ConversationMessageSenderEnum["user"],
    });
    const _subject = sendMessage({
      userMessage,
    });
    set({ isMagicSending: true });
    _subject.subscribe({
      next: (data) => {
        setText(data.message.replace(/^\s+|\s+$/g, ""));
      },
      complete: () => {
        set({ isMagicSending: false });
      },
      error: () => {
        set({ isMagicSending: false });
      },
    });
    return userMessage;
  },
}));
