/** @jsxImportSource @emotion/react */
import { Spinner } from "@/components/Spinner";
import { css } from "@emotion/react";
import { memo } from "react";

export const PageLoading = memo(() => {
  return (
    <div
      css={css`
        padding-bottom: 44px;
      `}
      className="w-screen h-screen flex flex-col items-center justify-center"
    >
      <Spinner />
    </div>
  );
});
