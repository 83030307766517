import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

export interface GiftExChangeStoreState {
  code: string;
  name: string | undefined;
  displayPrice: number | undefined;
  orderId: string;
  isExchange: boolean;
  setCode: (code: string) => void;
  setName: (name: string | undefined) => void;
  setDisplayPrice: (displayPrice: number | undefined) => void;
  setOrderId: (orderId: string) => void;
  setIsExchange: (isExchange: boolean) => void;
}

export const useGiftExChangeStateStore = create(
  subscribeWithSelector<GiftExChangeStoreState>((set, get) => ({
    code: "",
    name: undefined,
    displayPrice: undefined,
    orderId: "",
    isExchange: false,
    setCode: (code) => set({ code }),
    setName: (name) => set({ name }),
    setDisplayPrice: (displayPrice) => set({ displayPrice }),
    setOrderId: (orderId) => set({ orderId }),
    setIsExchange: (isExchange) => set({ isExchange }),
  }))
);
