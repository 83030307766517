/** @jsxImportSource @emotion/react */
import Button from "@/components/Button";
import { createGuard } from "@/containers/Guards";
import { useLoginUserStore } from "@/store/loginUser";
import { AppStorage } from "@/store/storageStore";
import { rowGapUtils } from "@/utils/css";
import { css } from "@emotion/react";
import { Checkbox } from "@mui/material";
import { memo, useCallback, useState } from "react";

export const Disclaimer = memo(() => {
  const [checkValue, setCheckValue] = useState(true);
  const { setNotShowDisclaimer } = useLoginUserStore((state) => state);
  const handleCheckChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCheckValue(event.target.checked);
    },
    []
  );

  const handleSure = useCallback(() => {
    AppStorage.setItem("notShowDisclaimer", checkValue);
    setNotShowDisclaimer(true);
  }, [checkValue, setNotShowDisclaimer]);

  return (
    <div
      className="flex flex-col w-11/12 m-auto"
      css={css`
        padding: 12px;
      `}
    >
      <div
        className="flex flex-col leading-loose"
        css={[
          css`
            font-size: 12px;
            p {
              color: #707070;
            }
          `,
          rowGapUtils(20),
        ]}
      >
        <h1
          className="font-bold  m-auto"
          css={css`
            font-size: 16px;
          `}
        >
          声明
        </h1>
        <p>
          本产品提供的技术服务回复内容基于第三方接口平台，使用者承诺不从事或宣传任何非法活动，包括但不限于违反宪法基本原则、危害国家安全、泄露机密、颠覆政权、破坏国家统一、损害国家荣誉与利益、煽动仇恨和歧视、宣扬邪教和迷信、散布谣言、扰乱社会秩序和稳定、宣扬淫秽、暴力、赌博和恐怖等行为。
        </p>
        <p>
          本产品回复内容由第三方接口平台产生，产品使用者应当为具有事实及信息判断能力之完全民事行为能力人，不具有完全民事行为能力及未满十八周岁者未经法定监护人允许不得擅自使用本产品，本产品对违反本免责声明擅自使用造成损害的不承担任何损失或损害责任。。
        </p>
        <p>
          为保证产品运行符合中华人民共和国法律规定，本产品提供方有权基于法律规定在任何时间调整产品内容、呈现方式等产品要素，同时本产品提供方有权对现有及将有的产品使用者及用户账户随时采取访问内容，远程控制，收回许可，封禁账户等措施以确保产品的使用符合中华人民共和国法律法规及产品服务协议之规定。
        </p>
        <p>使用本产品即表示接受本免责声明。</p>
      </div>
      <Button
        backGroundColor={"#333"}
        textColor="#fff"
        css={css`
          height: 36px;
          font-size: 14px;
          margin-top: 30px;
          margin-bottom: 14px;
          padding: 8px;
          line-height: 1;
          font-size: 14px;
        `}
        onClick={handleSure}
      >
        我已知晓
      </Button>
      <div
        className="flex flex-row justify-center items-center"
        css={css`
          font-size: 12px;
        `}
      >
        <Checkbox
          checked={checkValue}
          value={checkValue}
          onChange={handleCheckChange}
          css={css`
            padding: 2px;
          `}
        />
        不再提示
      </div>
    </div>
  );
});
export const DisclaimerGuard = createGuard({
  description: "使用声明",
  runCondition: function () {
    return !useLoginUserStore.getState().notShowDisclaimer;
  },
  render: () => <Disclaimer />,
  subscribe: (runGuard) => {
    useLoginUserStore.subscribe(() => runGuard());
  },
});
