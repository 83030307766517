import { BeeToken } from "@/services/models/user";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
interface AuthStoreState {
  token?: string;
  tokenInfo?: BeeToken;
  setTokenInfo: (value?: BeeToken) => void;
  entry?: "console" | "wechat" | "shared";
  setEntry: (entry: AuthStoreState["entry"]) => void;
  tokenStatus: "expired" | "error" | "unset" | "set";
  setTokenStatus: (tokenStatus: AuthStoreState["tokenStatus"]) => void;
}

export const useAuthStore = create(
  persist<AuthStoreState>(
    (set, get) => ({
      token: undefined,
      tokenStatus: "unset",
      setTokenInfo(tokenInfo) {
        set({
          tokenInfo,
          tokenStatus: tokenInfo ? "set" : "unset",
          token: tokenInfo ? tokenInfo.access_token : undefined,
        });
      },
      setEntry(entry) {
        set({ entry });
      },
      setTokenStatus(tokenStatus) {
        const { token } = get();
        set({
          token: tokenStatus === "set" ? token : undefined,
          tokenStatus,
        });
      },
    }),
    {
      name: "bee-auth",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
