import { ChatRoleParamMap } from "@/services/models/chart";
import produce from "immer";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

//储存role 的一些配置信息
interface ConversationRoleState {
  roles: Partial<ChatRoleParamMap>;
  setRole: <T extends keyof ChatRoleParamMap>(
    role: T,
    state: ChatRoleParamMap[T]
  ) => void;
}

export const useConversationRoleStore = create(
  persist<ConversationRoleState>(
    (set, get) => ({
      roles: {},
      setRole: (roleKey, value) => {
        const { roles } = get();
        set({
          roles: produce(roles, (draft) => {
            Object.assign(draft, { [roleKey]: value });
          }),
        });
      },
    }),
    {
      name: "bee-conversationRoles",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
