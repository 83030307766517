import { EncooHttpService } from "@/services/EncooHttpService";
import { UserServicesType } from "@/services/models/user";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

export interface UserServiceStoreState {
  userServicers: UserServicesType[];
  refreshUserServicersClient: () => Promise<UserServicesType[]>;
}

export const useUserServiceStore = create(
  subscribeWithSelector<UserServiceStoreState>((set, get) => ({
    userServicers: [],
    refreshUserServicersClient: async () => {
      const userServicers = await EncooHttpService.userService.getUserServices({
        valid: true,
      });
      set({ userServicers });
      return userServicers;
    },
  }))
);
