import { HttpClient } from "@/services/apis/shared";
import { PayProduct } from "@/services/models/product";

export class EncooProductHttpClient extends HttpClient {
  public async getProducts() {
    const { data } = await this.request<PayProduct[]>({
      method: "GET",
      url: `/payment/api/Products`,
    });
    return data;
  }

  public async getProduct(productId: string) {
    const { data } = await this.request<PayProduct>({
      method: "GET",
      url: `/payment/api/Products/${productId}`,
    });
    return data;
  }
}
