import { NotificationConfig } from "@/containers/Providers/Notification";
import { create } from "zustand";

export interface NotificationStorState {
  message?: Omit<NotificationConfig, "key">;
  setMessage: (message: NonNullable<NotificationStorState["message"]>) => void;
}
export const useNotificationStore = create<NotificationStorState>((set) => ({
  message: undefined,
  setMessage: (message) => set({ message }),
}));
