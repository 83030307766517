import { shareWx } from "@/hooks/wx";
import { EncooHttpService } from "@/services/EncooHttpService";
import { LoginUser, UserProfile } from "@/services/models/user";
import { useAuthStore } from "@/store/auth";
import { useChartQuotaStore } from "@/store/chartQuota";
import { useOrderStore } from "@/store/order";
import { useProductStore } from "@/store/product";
import { useTriggerShareStore } from "@/store/share";
import { AppStorage } from "@/store/storageStore";
import { useUserServiceStore } from "@/store/userService";
import wx from "weixin-js-sdk";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

export interface LoginUserStoreState extends Partial<LoginUser> {
  isFollowError: boolean;
  isFollowLoading: boolean;
  notShowDisclaimer: boolean;
  isJump: boolean;
  userProfile?: UserProfile;
  isFollow?: boolean;
  setNotShowDisclaimer: (notShowDisclaimer: boolean) => void;
  init: () => Promise<void>;
  refreshFollow: () => Promise<void>;
  refreshWxConfig: () => Promise<void>;
}
export const useLoginUserStore = create(
  subscribeWithSelector<LoginUserStoreState>((set, get) => ({
    id: undefined,
    isFollowLoading: false,
    isFollowError: false,
    notShowDisclaimer: AppStorage.getItem("notShowDisclaimer", false),
    isFollow: undefined,
    isJump: false,

    init: async () => {
      const token = useAuthStore.getState().token;
      const { refreshFollow, refreshWxConfig } = get();
      if (token) {
        refreshFollow();
        refreshWxConfig();
        useChartQuotaStore.getState().refresh();
        useOrderStore.getState().refreshOrdersClient();
        useUserServiceStore.getState().refreshUserServicersClient();
        useProductStore.getState().refreshProductsClient();
        useTriggerShareStore.getState().refreshInvitationTaskClient();
      }
    },
    setNotShowDisclaimer: (notShowDisclaimer) => {
      set({
        notShowDisclaimer,
      });
    },
    refreshFollow: async () => {
      set({ isFollowLoading: true });
      try {
        const isFollow = await EncooHttpService.profile.getFollow();
        set({ isFollow, isFollowError: false });
      } catch (error) {
        set({ isFollowError: true });
      } finally {
        set({ isFollowLoading: false });
      }
    },
    refreshWxConfig: async () => {
      try {
        const config = await EncooHttpService.profile.getWxConfig();
        const profile = await EncooHttpService.profile.getUserProfile();
        set({ userProfile: profile });
        wx.config({
          debug: false,
          appId: config.appId,
          timestamp: config.timestamp,
          nonceStr: config.nonceStr,
          signature: config.signature,
          beta: false,
          jsApiList: [
            "updateAppMessageShareData",
            "updateTimelineShareData",
            "chooseWXPay",
            "startRecord",
            "stopRecord",
            "onVoiceRecordEnd",
            "translateVoice",
            "chooseImage",
          ],
        });
        wx.error((e: unknown) => console.log(e));
        shareWx("index.html");
      } catch (error) {
        //
      } finally {
      }
    },
  }))
);

useAuthStore.subscribe((state, prevState) => {
  if (state.token !== prevState.token) {
    useLoginUserStore.getState().init();
  }
});
