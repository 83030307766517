import "@egjs/react-flicking/dist/flicking.css";
import { css } from "@emotion/react";
import "animate.css";

export default css`
  html {
    box-sizing: border-box;
  }
  html,
  body {
    height: auto;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  * {
    touch-action: pan-y;
    scrollbar-color: #c0c4cc transparent;
    scrollbar-width: thin;

    ::-webkit-scrollbar {
      width: 4px;
      height: 8px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #c0c4cc;

      :hover {
        background-color: #8f9bb3;
      }
    }
  }

  html {
    height: 100%;
  }

  body {
    background: transparent;
    color: #3d3d3d;
    padding: 0;
    margin: 0;
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: auto;
  }
`;
