import { EncooHttpService } from "@/services/EncooHttpService";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

export interface InvitationRecord {
  id: string;
  name: string;
  img: string;
  date: string;
}
export interface Welfare {
  type: string;
  quota: string;
  limited: string;
  tags: string[];
}
export interface MileageStep {
  step: number;
  total: number;
  current: number;
  rest: number;
  state: string;
  preState: string;
  welfare: Welfare[];
}

export type DigitalServiceType = "GPT_35" | "GPT_40";

export type PrizeStatus = "Locked" | "Free" | "Got";

export interface DailyQuotaDigitalServices {
  id: string;
  digitalServiceType: DigitalServiceType;
  name?: string;
  description?: string;
  dailyQuota: number;
  startOfValidityPeriod?: string;
  endOfValidityPeriod?: string;
  daysOfValidityPeriod?: number;
  unlimitedRate: boolean;
}

export interface PrizeOptions {
  id: string;
  name?: string;
  price: number;
  displayPrice?: number;
  description?: string;
  tag?: string;
  image?: string;
  purchasable: boolean;
  visible: boolean;
  dailyQuotaDigitalServices?: DailyQuotaDigitalServices[];
}

export interface InvitationTaskNode {
  index: number;
  lastNodeProgress?: number;
  nodeProgress: number;
  prizeOptions?: PrizeOptions[];
  prizeStatus: PrizeStatus;
}

export interface InvitedUser {
  joinTime: string;
  nickName?: string;
  icon?: string;
}

export interface InvitationTask {
  currentProgress: number;
  displayNodes?: InvitationTaskNode[];
  unlockedNodes?: InvitationTaskNode[];
  lockedNodes?: InvitationTaskNode[];
  latestInvitedUsers?: InvitedUser[];
}

export interface PrizeName {
  type?: string;
  quota?: string;
  limited?: string;
  price?: number;
  nodeProgress: number;
  prizeChoiceIndex: number;
}

export interface TakePrize {
  nodeProgress: number;
  prizeChoiceIndex: number;
}

export interface FeedbackTakePrize {
  success: boolean;
  message?: string;
}

export interface ShareStoreState {
  total: number;
  current: number;
  rest: number;
  currentProgress: number;
  prizeName: PrizeName[];
  latestInvitedUsers: InvitedUser[];
  currentMileage: InvitationTaskNode | undefined;
  displayMileage: InvitationTaskNode[];
  unlockedNodes: InvitationTaskNode[];
  lockedNodes: InvitationTaskNode[];
  unlockedToMileage: InvitationTaskNode | undefined;

  getTotal: (currentMileage: InvitationTaskNode | undefined) => void;
  getCurrent: (currentMileage: InvitationTaskNode | undefined) => void;
  getRest: () => void;
  getPrizeName: (currentMileage: InvitationTaskNode | undefined) => void;
  setCurrentMileage: (currentMileage: InvitationTaskNode) => void;
  refreshInvitationTaskClient: () => void;
  getPrizesClient: (payload: TakePrize) => Promise<FeedbackTakePrize>;
}

export const useTriggerShareStore = create(
  subscribeWithSelector<ShareStoreState>((set, get) => ({
    total: 0,
    current: 0,
    rest: 0,
    currentProgress: 0,
    prizeName: [],
    latestInvitedUsers: [],
    currentMileage: undefined,
    displayMileage: [],
    unlockedNodes: [],
    lockedNodes: [],
    unlockedToMileage: undefined,
    getTotal: (currentMileage) => {
      const total =
        (currentMileage?.nodeProgress ?? 0) -
        (currentMileage?.lastNodeProgress ?? 0);
      set({ total });
    },
    getCurrent: (currentMileage) => {
      const { currentProgress, total } = get();

      const restProgress =
        currentProgress - (currentMileage?.lastNodeProgress ?? 0);

      const current =
        restProgress >= 0 ? (restProgress > total ? total : restProgress) : 0;

      set({ current });
    },
    getRest: () => {
      const { total, current } = get();
      const rest = total - current;
      set({ rest });
    },
    getPrizeName: (currentMileage) => {
      const prizeName = currentMileage?.prizeOptions?.map((prize, index) => {
        const name = prize.name?.split(" ");
        return {
          type: name?.[0],
          quota: name?.[1],
          limited: name?.[2],
          price: prize.price,
          nodeProgress: currentMileage?.nodeProgress,
          prizeChoiceIndex: index,
        };
      });
      set({ prizeName });
    },
    setCurrentMileage: (currentMileage) => {
      set({ currentMileage });
    },
    refreshInvitationTaskClient: async () => {
      try {
        const invitationTask = await EncooHttpService.share.getInvitationTask();
        const {
          currentProgress,
          displayNodes,
          unlockedNodes,
          lockedNodes,
          latestInvitedUsers,
        } = invitationTask;
        const { getTotal, getCurrent, getRest, getPrizeName } = get();
        set({ currentProgress });
        set({ latestInvitedUsers });
        set({ unlockedNodes });
        set({ lockedNodes });

        if (displayNodes) {
          set({ displayMileage: displayNodes });

          const currentMileage = displayNodes.find(
            (node) => node.prizeStatus === "Locked"
          );
          set({ currentMileage });
          set({ unlockedToMileage: currentMileage });

          getPrizeName(currentMileage);

          getTotal(currentMileage);

          getCurrent(currentMileage);

          getRest();
        }
      } catch (error) {
        //
      }
    },
    getPrizesClient: async (payload) => {
      const prizes = await EncooHttpService.share.getPrizes(payload);
      return prizes;
    },
  }))
);
