/** @jsxImportSource @emotion/react */
import { newGuid } from "@/utils/shared";
import { css } from "@emotion/react";
import Fade from "@mui/material/Fade";
import classNames from "classnames";
import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import TransitionGroup from "react-transition-group/TransitionGroup";

export const MessageContext = createContext<{
  onOpen: (config: MessageConfig) => void;
  onClose: (key: string) => void;
}>({
  onOpen: function (config: MessageConfig) {},
  onClose: function (key: string) {},
});

interface MessageConfig {
  key: string;
  content: ReactNode;
  duration?: number;
}

export const MessageProvider = memo((props: { children?: ReactNode }) => {
  const { children } = props;

  const [messageList, setConfirmList] = useState<MessageConfig[]>([]);
  const onOpen = useCallback((config: MessageConfig) => {
    setConfirmList((list) => [...list, config]);
  }, []);
  const onClose = useCallback((key: string) => {
    setConfirmList((list) => list.filter((item) => item.key !== key));
  }, []);

  const contextValue = useMemo(() => {
    return {
      onOpen,
      onClose,
    };
  }, [onClose, onOpen]);

  return (
    <MessageContext.Provider value={contextValue}>
      <div
        className={classNames(
          "w-full fixed flex justify-center left-0",
          !messageList.length && "hidden"
        )}
        css={css`
          bottom: 100px;
          z-index: 1500;
        `}
      >
        <TransitionGroup>
          {messageList.map((item) => (
            <Fade key={item.key} in={true}>
              <div
                css={css`
                  border-radius: 4px;
                  background: rgba(0, 0, 0, 0.4);
                  color: #fff;
                  padding: 10px 16px;
                  margin: 10px auto;
                `}
              >
                {item.content}
              </div>
            </Fade>
          ))}
        </TransitionGroup>
      </div>
      {children}
    </MessageContext.Provider>
  );
});

export function useMessage() {
  const { onOpen, onClose } = useContext(MessageContext);
  return useCallback(
    (
      content: ReactNode,
      config?: Partial<{
        duration: number;
      }>
    ) => {
      const messageKey = newGuid();
      const { duration = 1500 } = config ?? {};
      onOpen({
        key: messageKey,
        content: content,
      });
      setTimeout(() => {
        onClose(messageKey);
      }, duration);
    },
    [onClose, onOpen]
  );
}
