/** @jsxImportSource @emotion/react */
import Button from "@/components/Button";
import SvgIcon from "@/components/SvgIcon";
import TextField from "@/components/TextField";
import Typography from "@/components/Typography";
import EventAction from "@/containers/Routers/Chart/skins/_internal/EventAction";
import {
  ConversationMessageRoleEnum,
  SmartRoleParam,
} from "@/services/models/chart";
import { useConversationRoleStore } from "@/store/role";
import { rowGapUtils } from "@/utils/css";
import { ReactComponent as IconClean } from "@assets/img/icon-clean.svg";
import { ClassNames, css } from "@emotion/react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab/Tab";
import produce from "immer";
import { get, memoize } from "lodash-es";
import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

const CustomPanel = memo(function (props: {
  onValueChange: (value: string[]) => void;
  value: string[];
}) {
  const { onValueChange, value } = props;

  const hanleInputChange = useMemo(
    () =>
      memoize((index: number) => (event: ChangeEvent<HTMLInputElement>) => {
        onValueChange(
          produce(value, (draft) => {
            draft[index] = event.target.value;
          })
        );
      }),
    [onValueChange, value]
  );
  const hanleCleanChange = useMemo(
    () =>
      memoize((index: number) => () => {
        onValueChange(
          produce(value, (draft) => {
            draft[index] = "";
          })
        );
      }),
    [onValueChange, value]
  );

  const hanleCleanInput = useCallback(() => {
    onValueChange([]);
  }, [onValueChange]);

  const customUsers = useMemo(() => {
    const numbers = ["一", "二", "三", "四", "五"];
    return Array(5)
      .fill("")
      .map((item, index) => (
        <TextField
          key={index}
          size="small"
          css={css`
            .MuiInputBase-root {
              font-size: 14px;
              padding-right: 0px;
            }
          `}
          placeholder={`请输入${numbers[index]}仁的名称`}
          value={value[index] ?? ""}
          onChange={hanleInputChange(index)}
          onClean={hanleCleanChange(index)}
        />
      ));
  }, [hanleCleanChange, hanleInputChange, value]);

  return (
    <div className="flex flex-col" css={rowGapUtils(14)}>
      {customUsers}
      <div className="flex justify-end">
        <Button
          startIcon={<SvgIcon SvgComponent={IconClean} />}
          textColor="#FA5151"
          onClick={hanleCleanInput}
        >
          一键清空
        </Button>
      </div>
    </div>
  );
});

const RandomPanel = memo(function (props: {
  onValueChange: (value: string) => void;
  value: string;
}) {
  const { onValueChange, value } = props;

  const hanleInputChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onValueChange(event.target.value);
    },
    [onValueChange]
  );

  const hanleCleanInput = useCallback(() => {
    onValueChange("");
  }, [onValueChange]);

  return (
    <ClassNames>
      {({ css }) => (
        <div className="flex flex-col">
          <Typography
            color="#999"
            type="subText"
            css={css`
              margin-bottom: 20px;
              line-height: 2;
            `}
          >
            AI有时会虚构人物，可以要求匹配真实人物(小众领域可能会杜撰或篡改履历)
          </Typography>
          <Typography
            color="#3D3D3D"
            type="text-bold"
            css={css`
              margin-bottom: 10px;
            `}
          >
            描述
          </Typography>
          <TextField
            css={css`
              .MuiInputBase-root {
                display: flex;
                align-items: flex-start;
                font-size: 14px;
                min-height: 100px;
                padding-bottom: 28px;
              }
            `}
            multiline
            placeholder="例如帮我找5位“xx领域专家”不超过20字"
            value={value.slice(0, 19)}
            onChange={hanleInputChange}
          />
          <div
            className="flex justify-end"
            css={css`
              font-size: 12px;
              margin: -24px 8px 10px 0;
              color: #9e9e9e;
            `}
          >
            {value.length}/20
          </div>

          <div className="flex justify-end">
            <Button
              startIcon={<SvgIcon SvgComponent={IconClean} />}
              textColor="#FA5151"
              onClick={hanleCleanInput}
            >
              一键清空
            </Button>
          </div>
        </div>
      )}
    </ClassNames>
  );
});

export const Setting = memo(function (props: { event: EventAction }) {
  const { event } = props;
  const { roles } = useConversationRoleStore((state) => state);
  const defaultValue = useMemo(() => {
    const mode = get(roles, "smart.payload.mode", "persons");
    return mode === "persons"
      ? {
          mode: mode,
          data: { persons: get(roles, "smart.payload.items", []) },
        }
      : {
          mode: mode,
          data: { prompt: get(roles, "smart.payload.prompt", "") },
        };
  }, [roles]);

  const [tabValue, setTabValue] = useState(defaultValue.mode);
  const [roleValue, setRoleValue] = useState<
    Partial<{
      persons: string[];
      prompt: string;
    }>
  >(defaultValue.data);

  const handleValueChange = useMemo(
    () =>
      memoize((key: "persons" | "prompt") => (value: string[] | string) => {
        setRoleValue((state) =>
          produce(state, (draft) => Object.assign(draft, { [key]: value }))
        );
      }),
    []
  );

  const options = useMemo(() => {
    return [
      {
        name: "指定五仁成员",
        key: "persons" as const,
        panel: (
          <CustomPanel
            value={roleValue.persons ?? []}
            onValueChange={handleValueChange("persons")}
          />
        ),
      },
      {
        name: "随机匹配五仁成员",
        key: "prompt" as const,
        panel: (
          <RandomPanel
            value={roleValue.prompt ?? ""}
            onValueChange={handleValueChange("prompt")}
          />
        ),
      },
    ];
  }, [roleValue, handleValueChange]);

  const handleTabChange = useCallback(
    (_event: React.SyntheticEvent, value: string) => {
      setTabValue(value);
    },
    []
  );

  useEffect(() => {
    event.subscribeOnce(
      "VALIDATE",
      (callback?: (role: Partial<SmartRoleParam>) => void) => {
        callback?.({
          role: ConversationMessageRoleEnum.smart,
          payload: Object.assign(
            {
              mode: tabValue,
            },
            tabValue === "persons"
              ? { items: get(roleValue, "persons", []) }
              : { prompt: get(roleValue, "prompt", "") }
          ) as SmartRoleParam["payload"],
        });
      }
    );
  }, [event, roleValue, tabValue]);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange} centered>
            {options.map((item) => (
              <Tab label={item.name} key={item.key} value={item.key} />
            ))}
          </TabList>
        </Box>
        {options.map((item) => (
          <TabPanel value={item.key} key={item.key}>
            {item.panel}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
});
