import { createMigration } from "@/containers/Migrations";
import { shareWx } from "@/hooks/wx";
import { wxShareConfig } from "@/shared/config/page";
import { useConversationCheckedStore } from "@/store/chart";

export const ChartStoreMigration = createMigration({
  description: "初始化 chart CheckedStore 一些默认值",
  runCondition: () => true,
  onRun: async () => {
    useConversationCheckedStore.setState({
      multiSelectedMessageIds: [],
      showCheckbox: false,
      showShareMask: false,
      showSetting: false,
    });
    shareWx("index.html", wxShareConfig.chart());
    return;
  },
});
