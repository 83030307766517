import { createMigration } from "@/containers/Migrations";
import { SizeType } from "@/services/models/drawing";
import { useAIDrawingStore } from "@/store/drawing";

export const DrawingInitMigration = createMigration({
  description: "Drawing 页面初始化",
  runCondition: () => true,
  onRun: async () => {
    useAIDrawingStore.setState({
      flickIndex: 999,
      scale: "LW11" as SizeType,
      inputText: "",
      isStartGeneration: false,
      generationProgress: 0,
      isFailed: false,
      isSucceed: false,
      generatedImageInformation: undefined,
    });
    return;
  },
});
