/** @jsxImportSource @emotion/react */
import { IconButton as MuiIconButton } from "@mui/material";
import {
  ForwardedRef,
  forwardRef,
  memo,
  MouseEventHandler,
  useCallback,
  useRef,
} from "react";

export type IconButtonProps = {
  isDisabled?: boolean;
  className?: string;
  title?: string;
  children?: React.ReactNode;
  disableRipple?: boolean; //禁止涟漪波动效果
  size?: "small" | "medium" | "large";
  mode?: "antishake" | "throttle" | null;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
};
export default memo(
  forwardRef(function IconButton(
    props: IconButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) {
    const {
      isDisabled,
      onClick,
      mode = "throttle",
      className,
      children,
      disableRipple,
      ...restProps
    } = props;
    const timerRef = useRef<ReturnType<typeof setTimeout> | undefined>(
      undefined
    );
    const canClickRef = useRef<boolean>(true);

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        switch (mode) {
          case "antishake":
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
              onClick?.(event);
              timerRef.current = undefined;
            }, 500);

            break;
          case "throttle":
            if (canClickRef.current) {
              canClickRef.current = false;
              onClick?.(event);
              setTimeout(() => {
                canClickRef.current = true;
              }, 500);
            }
            break;
          default:
            onClick?.(event);
            break;
        }
      },
      [onClick, mode]
    );

    return (
      <MuiIconButton
        ref={ref}
        className={className}
        disabled={isDisabled}
        disableRipple={disableRipple}
        onClick={handleClick}
        {...restProps}
      >
        {children}
      </MuiIconButton>
    );
  })
);
