import { v4 } from "uuid";

export function newGuid(): string {
  return v4();
}

export function cachePromise<T extends any[], R extends any>(
  fn: (...args: T) => Promise<R>
) {
  let cache: any = undefined;
  return async function (...args: T): Promise<R> {
    return (cache =
      cache ||
      (() => {
        return fn(...args).catch((e) => {
          cache = undefined;
          return Promise.reject(e);
        });
      })());
  };
}

export function assertNonNullish<T>(value: T): asserts value is NonNullable<T> {
  if (value == null) {
    throw new Error("Assert failed: value is nullish.");
  }
}

export function asNonNullish<T>(value: T | null | undefined): T {
  if (value == null) {
    throw new Error("Assert failed: value is nullish.");
  }
  return value;
}

export function recursion<T>(data: T, callback: (data: T) => T | undefined) {
  const nextData = callback && callback(data);
  if (nextData !== undefined) {
    recursion(nextData, callback);
  }
}

export function traversal<T>(
  data: T[],
  callback: (
    item: T,
    params: {
      index: number;
      level: number;
      parentData?: T;
      prevItem?: T;
      nextItem?: T;
    }
  ) => void,
  getChildren: (data: T) => T[],
  parentData?: T,
  level?: number
) {
  const internalLevel = level ?? 0;
  data.forEach((item, index) => {
    const preIndex = index - 1;
    const nextIndex = index + 1;
    callback(item, {
      index,
      level: internalLevel,
      parentData,
      prevItem: preIndex >= 0 ? data[preIndex] : undefined,
      nextItem: nextIndex < data.length ? data[nextIndex] : undefined,
    });
    traversal(
      getChildren(item),
      callback,
      getChildren,
      item,
      internalLevel + 1
    );
  });
}
