import { HttpClient } from "@/services/apis/shared";
import { PayOrder } from "@/services/models/order";
import { PrePaymentOrder } from "@/services/models/payment";

export class EncooOrderPaymentHttpClient extends HttpClient {
  public async createPrePaymentOrder(orderId: string) {
    const { data } = await this.request<PrePaymentOrder>({
      method: "PUT",
      url: `/payment/api/wechatpay/OrderPayments/jsapi/${orderId}`,
    });
    return data;
  }
  public async getPrevPaymentOrder(orderId: string) {
    const { data } = await this.request<PayOrder>({
      method: "GET",
      url: `/payment/api/wechatpay/OrderPayments/ordersync/${orderId}`,
    });
    return data;
  }
}
