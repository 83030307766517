import { useAuthStore } from "@/store/auth";
import { useLoginUserStore } from "@/store/loginUser";
import { queryStringify } from "@/utils/obj";
import { get } from "lodash-es";
import { useCallback } from "react";
const authBaseUrl = window._setting.auth.baseUrl;
export function useNavigateToAuthentication() {
  const { setTokenInfo, setEntry } = useAuthStore((state) => state);
  return useCallback(
    (params: Record<string, unknown>) => {
      const queryString = queryStringify(params);
      const scode = String(get(params, "scode", ""));
      setEntry(scode?.startsWith("x") ? "console" : "wechat");
      const destUrl = `${window.location.origin}${window.location.pathname}${
        queryString ? "?".concat(queryString) : ""
      }`;
      useLoginUserStore.setState({ isJump: true });
      setTokenInfo();
      window.location.replace(
        `${authBaseUrl}/v1/OAuth/wechat?destUrl=${encodeURIComponent(destUrl)}`
      );
    },
    [setEntry, setTokenInfo]
  );
}
