import { ImageScale, TemplateType } from "@/services/models/drawing";
import Img3DPixel from "@assets/img/drawing/img-3DPixel.png";
import ImgDisney from "@assets/img/drawing/img-disney.png";
import ImgGreeting from "@assets/img/drawing/img-greeting.png";
import ImgInk from "@assets/img/drawing/img-ink.png";
import ImgInset from "@assets/img/drawing/img-inset.png";
import ImgLogo from "@assets/img/drawing/img-logo.png";
import ImgMinimalism from "@assets/img/drawing/img-minimalism.png";
import ImgMiyazaki from "@assets/img/drawing/img-miyazaki.png";
import ImgPixar from "@assets/img/drawing/img-pixar.png";
import ImgQuadratic from "@assets/img/drawing/img-quadratic.png";
import ImgRealistic from "@assets/img/drawing/img-realistic.png";
import ImgSketch from "@assets/img/drawing/img-sketch.png";
import ImgTech from "@assets/img/drawing/img-tech.png";
import ImgTypography from "@assets/img/drawing/img-typography.png";

import { ReactComponent as IconFirst } from "@assets/img/imageScale/icon-first.svg";
import { ReactComponent as IconSecond } from "@assets/img/imageScale/icon-second.svg";
import { ReactComponent as IconThird } from "@assets/img/imageScale/icon-third.svg";

export const imageScale: readonly ImageScale[] = [
  {
    key: 1,
    label: "1:1",
    value: "LW11",
    icon: IconFirst,
  },
  {
    key: 2,
    label: "4:7",
    value: "LW47",
    icon: IconSecond,
  },
  {
    key: 3,
    label: "7:4",
    value: "LW74",
    icon: IconThird,
  },
];

export const templateTypeConfig: TemplateType[] = [
  {
    key: 0,
    type: "greeting",
    name: "贺卡",
    img: ImgGreeting,
    desc: "贺卡| Create a heartwarming tall greeting card. {text}",
  },
  {
    key: 1,
    type: "inset",
    name: "插画",
    img: ImgInset,
    desc: "插画 | 2d flat vector of {text} isolate on a white background",
  },
  {
    key: 2,
    type: "logo",
    name: "logo设计",
    img: ImgLogo,
    desc: "logo设计 |  'A  logo of {text} , transparent background'",
  },
  {
    key: 3,
    type: "typography",
    name: "字体设计",
    img: ImgTypography,
    desc: "字体设计 | typography design with the text '{text}' isolate on a white background",
  },
  {
    key: 4,
    type: "3DPixel",
    name: "3D像素模型",
    img: Img3DPixel,
    desc: "3D像素模型 |3D voxel art model of {text}",
  },
  {
    key: 5,
    type: "sketch",
    name: "人物素描",
    img: ImgSketch,
    desc: "人物素描 |Smudged a highly detailed charcoal portrait of {text}",
  },
  {
    key: 6,
    type: "ink",
    name: "水墨画风",
    img: ImgInk,
    desc: "水墨画风格 |A Chinese Ink Painting of {text}",
  },
  {
    key: 7,
    type: "realistic",
    name: "真人写实风",
    img: ImgRealistic,
    desc: "真人写实风格 |A Photo with Photorealistic style, {text}",
  },
  {
    key: 8,
    type: "tech",
    name: "科技酷炫风",
    img: ImgTech,
    desc: "科技炫酷风格 | An image illuminated in Acidwave style, {text}",
  },
  {
    key: 9,
    type: "minimalism",
    name: "现代极简风",
    img: ImgMinimalism,
    desc: "现代极简风格 | Aesthetic with post-modern minimalist style, {text}",
  },
  {
    key: 10,
    type: "miyazaki",
    name: "宫崎骏风",
    img: ImgMiyazaki,
    desc: "宫崎骏风格| a Miyazaki style image, {text}",
  },
  {
    key: 11,
    type: "quadratic",
    name: "二次元风",
    img: ImgQuadratic,
    desc: "二次元风格|A ACGN culture-style image with a dreamy anime-style watercolor portrait of {text}",
  },
  {
    key: 12,
    type: "pixar",
    name: "皮克斯风",
    img: ImgPixar,
    desc: "皮克斯动画 | Craft a heartwarming 3D Pixar-style cartoon moment with {text}",
  },
  {
    key: 13,
    type: "disney",
    name: "迪士尼风",
    img: ImgDisney,
    desc: "迪士尼风格| A Disney-style image {text}",
  },
];
