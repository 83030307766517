import { HttpClientMiddleware } from "@/services/apis/shared";
const authorizationHeaderName = "authorization";

export const createAuthorizationMiddleware = (
  getAccessToken: () => Promise<string | null>
): HttpClientMiddleware => {
  return async (config, next) => {
    if (!config.headers) {
      config.headers = {};
    }
    if (config.headers[authorizationHeaderName] === undefined) {
      const token = await getAccessToken();

      if (token) {
        config.headers[authorizationHeaderName] = "Bearer " + token;
      }
    }
    return next(config);
  };
};
