import { HttpClient } from "@/services/apis/shared";
import { PayOrder } from "@/services/models/order";

export class EncooOrderHttpClient extends HttpClient {
  public async getOrders() {
    const { data } = await this.request<PayOrder[]>({
      method: "GET",
      url: `/payment/api/orders`,
    });
    return data;
  }

  public async createOrder(
    items?: {
      productId: string;
      quantity: number;
    },
    couponCode?: string
  ) {
    const { data } = await this.request<PayOrder>({
      method: "POST",
      url: `/payment/api/orders`,
      data: {
        items: items ? [items] : [],
        couponCode,
        appName: "Beezy",
      },
    });
    return data;
  }

  public async getOrder(orderId: string) {
    const { data } = await this.request<PayOrder>({
      method: "GET",
      url: `/payment/api/orders/${orderId}`,
    });
    return data;
  }
  public async cancelOrder(orderId: string) {
    const { data } = await this.request<PayOrder>({
      method: "GET",
      url: `/payment/api/orders/${orderId}/cancel`,
    });
    return data;
  }
}
