/** @jsxImportSource @emotion/react */
import { createGuard } from "@/containers/Guards";
import { useLoginUserStore } from "@/store/loginUser";
import { rowGapUtils } from "@/utils/css";
import { css } from "@emotion/react";
import { memo } from "react";

export const SubscriptionView = memo(() => {
  return (
    <div
      className="flex flex-col items-center justify-center flex-1"
      css={rowGapUtils(30)}
    >
      <div
        className="text-center"
        css={css`
          width: 100%;
          font-size: 16px;
          line-height: 32px;
          .ant-btn {
            padding: 0px;
          }
        `}
      >
        长按图片/下载，扫码关注公众号，
        <br />
        就能与喳喳蜂开启畅聊啦
        <div>👇 👇 👇</div>
      </div>
      <div
        css={css`
          width: 180px;
          height: 180px;
          margin: 0px auto;
        `}
      >
        <img src="/images/bee_qr_static.png" alt="" />
      </div>
    </div>
  );
});
export const SubscriptionViewGuard = createGuard({
  description: "",
  runCondition: function () {
    return !useLoginUserStore.getState().isFollow;
  },
  render: () => <SubscriptionView />,
  subscribe: (runGuard) => {
    useLoginUserStore.subscribe(() => runGuard());
  },
});
