/** @jsxImportSource @emotion/react */
import Modal from "@/components/Modal";
import { newGuid } from "@/utils/shared";
import { css } from "@emotion/react";
import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export const ConfirmContext = createContext<{
  onCreate: (config: ConfirmConfig) => void;
  onUpdate: (config: ConfirmConfig) => void;
  onClose: (key: string) => void;
}>(undefined!);
type CreateConfirmContentAction = {
  onCancel: () => void;
  onUpdate: (config: Omit<ConfirmConfig, "key">) => void;
};

export interface CreateConfirmConfig {
  onOk?: () => void;
  okText?: string;
  okVisible?: boolean;
  cancelVisible?: boolean;
  cancelText?: string;
  onCancel?: () => void;
  content: ReactNode | ((action: CreateConfirmContentAction) => ReactNode);
  title?: ReactNode;
  icon?: ReactNode;
}
export interface ConfirmConfig extends Omit<CreateConfirmConfig, "content"> {
  key: string;
  content: ReactNode;
}

export const ConfirmProvider = memo((props: { children?: ReactNode }) => {
  const { children } = props;

  const [confirmList, setConfirmList] = useState<ConfirmConfig[]>([]);
  const onCreate = useCallback((config: ConfirmConfig) => {
    setConfirmList((list) => [...list, config]);
  }, []);

  const onUpdate = useCallback((config: ConfirmConfig) => {
    setConfirmList((list) =>
      list.map((item) => (item.key === config.key ? config : item))
    );
  }, []);

  const onClose = useCallback((key: string) => {
    setConfirmList((list) => list.filter((item) => item.key !== key));
  }, []);

  const contextValue = useMemo(() => {
    return {
      onCreate,
      onClose,
      onUpdate,
    };
  }, [onClose, onCreate, onUpdate]);

  return (
    <ConfirmContext.Provider value={contextValue}>
      {confirmList.map((item) => (
        <Modal
          css={css`
            .MuiPaper-root {
              overflow: visible;
            }
            .MuiDialog-paper {
              border-radius: 16px;
            }
            .modal-content {
              position: relative;
              display: flex;
              color: #757575;
              align-items: center;
              justify-content: center;
              width: 80vw;
              min-height: 120px;
            }
          `}
          onCancel={item.cancelVisible ? item.onCancel : undefined}
          onOk={item.okVisible ? item.onOk : undefined}
          isVisible={true}
          icon={item.icon}
          hasMask={true}
          okText={item.okText}
          cancelText={item.cancelText}
          maskClosable={false}
          key={item.key}
          title={item.title}
        >
          {item.content}
        </Modal>
      ))}
      {children}
    </ConfirmContext.Provider>
  );
});

export function useConfirm() {
  const { onCreate, onClose, onUpdate } = useContext(ConfirmContext);
  const [confirmKeys, setConfirmKeys] = useState<string[]>([]);

  const handleRemoveKey = useCallback(
    (confirmKey: string) => {
      onClose(confirmKey);
      setConfirmKeys((listKeys) =>
        listKeys.filter((key) => key !== confirmKey)
      );
    },
    [onClose]
  );

  useEffect(() => {
    return () => {
      confirmKeys.forEach((key: string) => {
        onClose(key);
      });
    };
  }, [confirmKeys, onClose]);

  return useCallback(
    (config: CreateConfirmConfig) => {
      const ConfirmKey = newGuid();
      const action: CreateConfirmContentAction = {
        onCancel: () => {
          try {
            config.onCancel?.();
          } finally {
            handleRemoveKey(ConfirmKey);
          }
        },
        onUpdate: (config: Omit<ConfirmConfig, "key">) => {
          onUpdate({ ...config, key: ConfirmKey });
        },
      };
      onCreate({
        key: ConfirmKey,
        onCancel: action.onCancel,
        content:
          typeof config.content === "function"
            ? config.content(action)
            : config.content,
        title: config.title,
        okText: config.okText,
        cancelText: config.cancelText,
        icon: config.icon,
        okVisible: config.okVisible ?? true,
        cancelVisible: config.cancelVisible ?? true,
        onOk: () => {
          try {
            config.onOk?.();
          } finally {
            handleRemoveKey(ConfirmKey);
          }
        },
      });
      setConfirmKeys((confirmKeys) => [...confirmKeys, ConfirmKey]);
      return ConfirmKey;
    },
    [handleRemoveKey, onCreate, onUpdate]
  );
}
export type UseConfirmType = ReturnType<typeof useConfirm>;
