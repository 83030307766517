import { EncooHttpService } from "@/services/EncooHttpService";
import { PayProduct } from "@/services/models/product";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

export interface ProductStoreState {
  products: PayProduct[];
  refreshProductsClient: () => Promise<PayProduct[]>;
}

export const useProductStore = create(
  subscribeWithSelector<ProductStoreState>((set, get) => ({
    products: [],
    refreshProductsClient: async () => {
      const _products = await EncooHttpService.product.getProducts();
      set({ products: _products });
      return _products;
    },
  }))
);
