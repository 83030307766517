/** @jsxImportSource @emotion/react */
import Button from "@/components/Button";
import { createGuard } from "@/containers/Guards";
import { useLoginUserStore } from "@/store/loginUser";
import { css } from "@emotion/react";
import { memo } from "react";

export const SubscriptionError = memo(() => {
  const refreshFollow = useLoginUserStore((state) => state.refreshFollow);
  return (
    <div
      css={css`
        padding-bottom: 44px;
      `}
      className="w-screen h-screen flex flex-col items-center justify-center flex-shrink-0"
    >
      <div
        css={css`
          text-align: center;
        `}
      >
        服务异常， 请稍后尝试。
      </div>
      <Button
        css={css`
          width: 60px;
          margin-top: 10px;
        `}
        onClick={refreshFollow}
        className="font-bold"
        backGroundColor="#ffcc00"
        textColor="#141414"
      >
        重试
      </Button>
    </div>
  );
});
export const SubscriptionErrorGuard = createGuard({
  description: "登录异常实现",
  runCondition: () => {
    return useLoginUserStore.getState().isFollowError;
  },
  render: () => <SubscriptionError />,
  subscribe: (runGuard) => {
    useLoginUserStore.subscribe(() => runGuard());
  },
});
