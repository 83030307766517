import { templateTypeConfig } from "@/containers/Routers/Drawing/_config";
import { EncooHttpService } from "@/services/EncooHttpService";
import { SizeType } from "@/services/models/drawing";
import { useChartQuotaStore } from "@/store/chartQuota";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

export interface AIDrawingState {
  flickIndex: number;
  scale: SizeType;
  inputText: string;
  isStartGeneration: boolean;
  generationProgress: number;
  isFailed: boolean;
  isSucceed: boolean;
  generatedImageInformation: string | undefined;
  setFlickIndex: (flickIndex: number) => void;
  setScale: (scale: SizeType) => void;
  setInputText: (inputText: string) => void;
  setIsStartGeneration: (startGeneration: boolean) => void;
  setGenerationProgress: (generationProgress: number) => void;
  setIsFailed: (isFailed: boolean) => void;
  setIsSucceed: (isSucceed: boolean) => void;
  getQuota: () => number;
  skipToFail: () => void;
  generateImage: (
    prompt: string,
    sizeType: SizeType,
    skipToFail?: () => void
  ) => void;
  setGeneratedImageInformation: (
    generatedImageInformation: string | undefined
  ) => void;
  startGeneration: () => void;
}

export const useAIDrawingStore = create(
  subscribeWithSelector<AIDrawingState>((set, get) => ({
    flickIndex: 999,
    scale: "LW11" as SizeType,
    inputText: "",
    isStartGeneration: false,
    generationProgress: 0,
    isFailed: false,
    isSucceed: false,
    generatedImageInformation: undefined,
    setFlickIndex: (flickIndex) => set({ flickIndex }),
    setScale: (scale) => set({ scale }),
    setInputText: (inputText) => set({ inputText }),
    setIsStartGeneration: (isStartGeneration) => set({ isStartGeneration }),
    setGenerationProgress: (generationProgress) => set({ generationProgress }),
    setIsFailed: (isFailed) => set({ isFailed }),
    setIsSucceed: (isSucceed) => set({ isSucceed }),
    setGeneratedImageInformation: (generatedImageInformation) =>
      set({ generatedImageInformation }),
    getQuota: () => {
      const { imageQuota } = useChartQuotaStore.getState();
      const quota = Number(
        Number(imageQuota?.max ?? 0) - Number(imageQuota?.used ?? 0)
      );
      return quota > 0 ? quota : 0;
    },
    skipToFail: () => {
      const {
        setIsFailed,
        setGeneratedImageInformation,
        setGenerationProgress,
        setIsStartGeneration,
      } = get();
      setIsStartGeneration(false);
      setIsFailed(true);
      setGeneratedImageInformation(undefined);
      setGenerationProgress(0);
    },
    generateImage: async (prompt: string, sizeType: SizeType) => {
      const {
        setGenerationProgress,
        setIsSucceed,
        skipToFail,
        setGeneratedImageInformation,
        setIsStartGeneration,
      } = get();
      const { refresh } = useChartQuotaStore.getState();
      try {
        const drawingData = await EncooHttpService.drawing.generateImage({
          prompt,
          sizeType,
          skipToFail,
        });

        if (drawingData) {
          const { data } = drawingData;
          if (data && data.length > 0) {
            setIsStartGeneration(false);
            setIsSucceed(true);
            setGeneratedImageInformation(
              `data:image/jpeg;base64,${data[0].b64_json}`
            );
            setGenerationProgress(100);
            refresh();
          } else {
            skipToFail();
            refresh();
          }
        }
      } catch (err) {
        skipToFail();
        refresh();
        console.log(err);
      }
    },
    startGeneration: async () => {
      const { flickIndex, scale, inputText, generateImage, skipToFail } = get();
      if (flickIndex <= 13) {
        const desc = templateTypeConfig.filter(
          (item) => item.key === flickIndex
        )?.[0]?.desc;
        const prompt = desc.replace("{text}", inputText);
        generateImage(prompt, scale, skipToFail);
      } else {
        generateImage(inputText, scale, skipToFail);
      }
    },
  }))
);
