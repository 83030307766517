/** @jsxImportSource @emotion/react */
import IconButton from "@/components/IconButton";
import SvgIcon from "@/components/SvgIcon";
import { ReactComponent as IconClose } from "@assets/img/icon-close2.svg";
import { css } from "@emotion/css";
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import {
  ForwardedRef,
  KeyboardEvent,
  forwardRef,
  memo,
  useCallback,
} from "react";
export type MuiTextFieldStyledProps = {
  backGroundColor?: string;
  borderColor?: string;
  textColor?: string;
  fontSize?: string;
  textFormat?: string;
  onValueChange?: (value: string) => void;
  onEnter?: (value: string) => void;
  onClean?: () => void;
};
export type TextFieldProps = MuiTextFieldProps & MuiTextFieldStyledProps;

export default memo(
  forwardRef(function TextField(
    props: TextFieldProps,
    ref: ForwardedRef<HTMLDivElement>
  ) {
    const {
      backGroundColor,
      borderColor,
      textColor,
      fontSize,
      textFormat,
      onClean,
      onChange,
      onEnter,
      onValueChange,
      ...restProps
    } = props;

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        onChange?.(event);
        onValueChange?.(event.currentTarget.value ?? "");
      },
      [onChange, onValueChange]
    );

    const handleKeyChange = useCallback(
      (event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event.key === "Enter") {
          onEnter?.(event.currentTarget.value);
          event.preventDefault();
        }
      },
      [onEnter]
    );

    return (
      <MuiTextField
        css={[
          props.disabled &&
            css`
              background-color: #f8f8f8;
            `,
          borderColor &&
            css`
              .MuiOutlinedInput-root {
                fieldset {
                  border-color: ${borderColor};
                }
              }
            `,
          backGroundColor &&
            css`
              .MuiOutlinedInput-root {
                background-color: ${backGroundColor};
              }
            `,
          textColor &&
            css`
              .MuiOutlinedInput-root {
                color: ${textColor};
              }
            `,
          fontSize &&
            css`
              .MuiOutlinedInput-root {
                font-size: ${fontSize};
              }
            `,
          css`
            .MuiOutlinedInput-root {
              text-decoration: ${textFormat ?? "none"};
            }
            .MuiFormHelperText-root {
              text-align: center;
            }
          `,
        ]}
        {...restProps}
        onChange={handleChange}
        InputProps={{
          endAdornment:
            props.value && onClean ? (
              <IconButton onClick={onClean}>
                <SvgIcon SvgComponent={IconClose} value={18} />
              </IconButton>
            ) : null,
          onKeyDown: handleKeyChange,
        }}
        ref={ref}
      />
    );
  })
);
