/** @jsxImportSource @emotion/react */
import Button from "@/components/Button";
import { MaskImage } from "@/components/MaskImage";
import { TaskStorage, createTask } from "@/containers/Tasks";
import ImgTemporary from "@assets/img/tasks/task-new.png";
import { css } from "@emotion/react";
import dayjs from "dayjs";

export const TemporaryTask = createTask({
  name: "分享弹窗上线通知",
  description: "需求6.1-6.15日  每日展示",
  runCondition: () => {
    const storeDate = TaskStorage.getItem("temporary");
    const nowDate = dayjs().format("YYYY-MM-DD");
    // var day = dayjs().day();
    // var week = ["日", "一", "二", "三", "四", "五", "六"][day];
    if (nowDate >= "2023-06-01" && nowDate <= "2023-06-15") {
      if (!storeDate || nowDate > storeDate) {
        return true;
      } else return false;
    } else {
      return false;
    }
  },
  onRun: async ({ mask, navigate }, next) => {
    // localStorage 存储当天信息
    const nowDate = dayjs().format("YYYY-MM-DD");
    TaskStorage.setItem("temporary", nowDate);
    mask({
      content: (
        <div className="text-center">
          <MaskImage
            src={ImgTemporary}
            onClick={() => window.open("https://t.1yb.co/JH39")}
          />
          <div
            css={css`
              margin-top: 20px;
            `}
          >
            <Button
              type="text"
              onClick={() => window.open("https://t.1yb.co/JH39")}
            >
              点击链接→ 即可加群
            </Button>
          </div>
        </div>
      ),
      onClose: next,
    });
  },
});
