import { ReadonlyUnion } from "@/types/shared";
import { createStorage } from "@/utils/localStorage";
import { AsyncMiddlewareFunType, asyncMiddleware } from "@/utils/middleware";
import { Type } from "@sinclair/typebox";
import { Fragment, ReactNode, memo, useEffect, useState } from "react";

export type MigrationStateType = {
  description?: string;
  runCondition: () => boolean;
  onRun: () => Promise<void>;
};
export const createMigration = (
  config: MigrationStateType
): MigrationStateType => config;

const MigrationStateSchema = Type.Object({
  conversionVersion: Type.String(),
});

export const MigrationStorage = createStorage(
  "bee-migrations",
  MigrationStateSchema
);

export const useMigrations = function (
  migrations: ReadonlyUnion<MigrationStateType>[]
) {
  const [isCompleted, setIsCompleted] = useState(false);
  useEffect(() => {
    const migrationActions = migrations.map((migration) => {
      return async (data, next) => {
        if (migration.runCondition()) {
          await migration.onRun();
        }
        await next(data);
      };
    }) as AsyncMiddlewareFunType<undefined, void>[];
    migrationActions.push(async (data, next) => {
      setIsCompleted(true);
      await next(data);
    });
    asyncMiddleware(migrationActions)(undefined);
  }, [migrations]);
  return isCompleted;
};

export const MigrationContainer = memo(
  (props: { migrations?: MigrationStateType[]; children?: ReactNode }) => {
    const { migrations = [], children } = props;
    const isMigrationCompleted = useMigrations(migrations);
    return isMigrationCompleted ? <Fragment>{children}</Fragment> : null;
  }
);
