/** @jsxImportSource @emotion/react */
import { newGuid } from "@/utils/shared";
import { css } from "@emotion/react";
import { Drawer as MuiDrawer } from "@mui/material";
import { get, memoize } from "lodash-es";
import {
  ReactNode,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export const DrawerContext = createContext<{
  onCreate: (config: DrawerConfig) => void;
  onUpdate: (config: DrawerConfig) => void;
  onClose: (key: string) => void;
}>(undefined!);
type CreateDrawerContentAction = {
  onCancel?: () => void;
  onUpdate: (config: Omit<DrawerConfig, "key">) => void;
};
export interface CreateDrawerConfig {
  onCancel?: () => void;
  content: ReactNode | ((action: CreateDrawerContentAction) => ReactNode);
  maskCloseable?: boolean;
  hasMask?: boolean;
}
interface DrawerConfig extends Omit<CreateDrawerConfig, "content"> {
  key: string;
  content: ReactNode;
}

export const DrawerProvider = memo((props: { children?: ReactNode }) => {
  const { children } = props;
  const [visibleMap, setVisibleMap] = useState<Record<string, boolean>>({});
  const [DrawerList, setDrawerList] = useState<DrawerConfig[]>([]);
  const onCreate = useCallback((config: DrawerConfig) => {
    setDrawerList((list) => [...list, config]);
    setTimeout(() => {
      setVisibleMap((visibleMap) => {
        return { ...visibleMap, [config.key]: true };
      });
    }, 10);
  }, []);

  const onUpdate = useCallback((config: DrawerConfig) => {
    setDrawerList((list) =>
      list.map((item) => (item.key === config.key ? config : item))
    );
  }, []);

  const onClose = useCallback((key: string) => {
    setVisibleMap((visibleMap) => {
      return { ...visibleMap, [key]: false };
    });
    setTimeout(() => {
      setDrawerList((list) => list.filter((item) => item.key !== key));
    }, 100);
  }, []);

  const contextValue = useMemo(() => {
    return {
      onCreate,
      onUpdate,
      onClose,
    };
  }, [onClose, onCreate, onUpdate]);

  const handleClose = useMemo(
    () =>
      memoize(
        (config: DrawerConfig) =>
          (
            _event: React.MouseEvent<Element>,
            reason: "backdropClick" | "escapeKeyDown"
          ) => {
            if (config.maskCloseable === false && reason === "backdropClick") {
              return;
            } else {
              config.onCancel?.();
            }
          }
      ),
    []
  );

  return (
    <DrawerContext.Provider value={contextValue}>
      {DrawerList.map((item) => {
        const { hasMask = true } = item;
        return (
          <MuiDrawer
            key={item.key}
            variant={hasMask ? undefined : "persistent"}
            anchor="bottom"
            open={get(visibleMap, item.key, false)}
            onClose={handleClose(item)}
            hideBackdrop={!hasMask}
            css={[
              css`
                .MuiPaper-root {
                  border-radius: 10px 10px 0 0;
                }
              `,
            ]}
          >
            <div
              className="flex flex-col"
              css={css`
                min-height: 80px;
                max-height: 90vh;
                overflow-y: auto;
              `}
            >
              <div
                className="flex flex-col"
                css={css`
                  padding-top: 4px;
                `}
              >
                {item.content}
              </div>
            </div>
          </MuiDrawer>
        );
      })}
      {children}
    </DrawerContext.Provider>
  );
});

export function useDrawer() {
  const { onCreate, onClose, onUpdate } = useContext(DrawerContext);
  const [DrawerKeys, setDrawerKeys] = useState<string[]>([]);

  const handleRemoveKey = useCallback(
    (DrawerKey: string) => {
      onClose(DrawerKey);
      setDrawerKeys((listKeys) => listKeys.filter((key) => key !== DrawerKey));
    },
    [onClose]
  );

  useEffect(() => {
    return () => {
      DrawerKeys.forEach((key: string) => {
        onClose(key);
      });
    };
  }, [DrawerKeys, onClose]);

  return useCallback(
    (config: CreateDrawerConfig) => {
      const Drawerkey = newGuid();
      const { onCancel, content, ...configProps } = config;
      const action: CreateDrawerContentAction = {
        onCancel: () => {
          try {
            onCancel?.();
          } finally {
            handleRemoveKey(Drawerkey);
          }
        },
        onUpdate: (config: Omit<DrawerConfig, "key">) => {
          onUpdate({ ...config, key: Drawerkey });
        },
      };
      onCreate({
        ...configProps,
        key: Drawerkey,
        onCancel: () => {
          try {
            onCancel?.();
          } finally {
            handleRemoveKey(Drawerkey);
          }
        },
        content: typeof content === "function" ? content(action) : content,
      });
      setDrawerKeys((DrawerKeys) => [...DrawerKeys, Drawerkey]);
      return Drawerkey;
    },
    [handleRemoveKey, onCreate, onUpdate]
  );
}
