import { HttpClient } from "@/services/apis/shared";
import { QuotaType } from "@/services/models/quota";

export class EncooQuotaHttpClient extends HttpClient {
  public async getQuotas() {
    const { data } = await this.request<QuotaType[]>({
      method: "GET",
      url: `/payment/api/Quotas/today`,
    });
    return data;
  }
}
