/** @jsxImportSource @emotion/react */
import Button from "@/components/Button";
import { MaskImage } from "@/components/MaskImage";
import { TaskStorage, createTask } from "@/containers/Tasks";
import ImgDrawing from "@assets/img/tasks/task-drawing.png";
import { css } from "@emotion/react";
import dayjs from "dayjs";

export const DrawingTask = createTask({
  name: "生图",
  description: "需求到1.31日  每日展示",
  runCondition: function useRunCondition() {
    const storeDate = TaskStorage.getItem("drawing");
    const nowDate = dayjs().format("YYYY-MM-DD");
    if (nowDate <= "2024-01-31") {
      if (!storeDate || nowDate > storeDate) {
        return true;
      } else return false;
    } else {
      return false;
    }
  },
  onRun: async ({ mask, navigate }, next) => {
    const nowDate = dayjs().format("YYYY-MM-DD");
    TaskStorage.setItem("drawing", nowDate);
    mask({
      content: (
        <div className="text-center">
          <MaskImage src={ImgDrawing} onClick={() => navigate("/drawing")} />
          <div
            css={css`
              margin-top: 20px;
            `}
          >
            <Button type="text" onClick={() => navigate("/drawing")}>
              新功能上线：全新AI”专业“生图功能，邀请体验！
            </Button>
          </div>
        </div>
      ),
      onClose: next,
    });
  },
});
