/** @jsxImportSource @emotion/react */
import Button from "@/components/Button";
import { createGuard } from "@/containers/Guards";
import { useNavigateToAuthentication } from "@/containers/Guards/_hooks";
import { useAuthStore } from "@/store/auth";
import { RecordKey } from "@/types/shared";
import { omit } from "@/utils/obj";
import { css } from "@emotion/react";
import { memo, useCallback } from "react";

export const AuthError = memo(() => {
  const { entry, tokenStatus } = useAuthStore((state) => state);
  const navigateToAuthentication = useNavigateToAuthentication();

  const retry = useCallback(async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const query = Object.fromEntries(searchParams) as Record<
      RecordKey,
      unknown
    >;
    navigateToAuthentication(omit(query, ["scode", "code"]));
  }, [navigateToAuthentication]);

  return (
    <div
      className="w-screen h-screen flex flex-col items-center justify-center flex-shrink-0"
      css={css`
        padding-bottom: 44px;
      `}
    >
      <div
        css={css`
          text-align: center;
          margin-bottom: 20px;
        `}
      >
        【{tokenStatus === "error" && "微信授权失败"}
        {tokenStatus === "expired" && "微信授权过期"}
        】
        <br />
        {entry === "console"
          ? "请在控制台重新扫码登录。"
          : "请稍后尝试或联系我们"}
      </div>
      <Button
        css={css`
          min-width: 60px;
          margin-top: 5px;
        `}
        onClick={retry}
        className="font-bold"
        backGroundColor="#ffcc00"
        textColor="#141414"
      >
        {entry === "console" ? "直接登录" : "重试"}
      </Button>
    </div>
  );
});

export const AuthErrorGuard = createGuard({
  description: "无权限",
  runCondition: function () {
    const { tokenStatus } = useAuthStore.getState();
    return tokenStatus === "error" || tokenStatus === "expired";
  },
  render: () => <AuthError />,
  subscribe: (runGuard) => {
    useAuthStore.subscribe(() => runGuard());
  },
});
