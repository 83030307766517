import { asyncMiddleware } from "@/utils/middleware";
import { cachePromise } from "@/utils/shared";
import { hashString } from "@/utils/string";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface HttpClientMiddleware<T = any> {
  (
    requestConfig: AxiosRequestConfig,
    next: (requestConfig: AxiosRequestConfig) => Promise<T>
  ): Promise<T>;
}
export type HttpRequestOption = {
  cacheTime: number;
  forceUpdate: boolean;
};
type HttpCacheState = {
  hash: number;
  updateTime: number;
  response: unknown;
};
type HttpThrottleState = {
  updateTime: number;
  cacheRequest: () => Promise<unknown>;
};

//TODO  需要改造Cache 和 Throttle 实现
export class HttpClient {
  private readonly _axios: AxiosInstance = axios.create();
  private readonly _middleWares: HttpClientMiddleware[] = [];
  private readonly _cache: Map<string, HttpCacheState> = new Map();
  private readonly _throttle: Map<number, HttpThrottleState> = new Map();

  constructor(middleWares?: HttpClientMiddleware[]) {
    middleWares?.forEach((item) => {
      this._middleWares.push(item);
    });
  }
  private _cacheAction = async (
    requestConfig: AxiosRequestConfig,
    request: () => Promise<unknown>,
    options?: Partial<HttpRequestOption>
  ) => {
    const { cacheTime, forceUpdate } = options ?? {};
    const hash = hashString(JSON.stringify(requestConfig));
    const url = requestConfig.url!;
    const cacheState = this._cache.get(url);
    const throttleState = this._throttle.get(hash);
    if (!throttleState) {
      this._throttle.set(hash, {
        updateTime: Date.now(),
        cacheRequest: cachePromise(() => request()),
      });
    }
    if (
      cacheTime &&
      cacheState &&
      cacheState.hash === hash &&
      Date.now() < cacheState.updateTime + cacheTime &&
      !forceUpdate
    ) {
      this._throttle.delete(hash);
      return cacheState.response;
    } else {
      const response = await this._throttle.get(hash)?.cacheRequest();
      if (cacheTime) {
        const cacheState: HttpCacheState = {
          updateTime: Date.now(),
          hash,
          response: response,
        };
        this._cache.set(url, cacheState);
      }
      this._throttle.delete(hash);
      return response;
    }
  };
  use(middleWares: HttpClientMiddleware | HttpClientMiddleware[]) {
    [middleWares].flat().forEach((middleware) => {
      this._middleWares.push(middleware);
    });
  }
  request<TResponse>(
    requestConfig: AxiosRequestConfig,
    options?: Partial<HttpRequestOption>
  ): Promise<AxiosResponse<TResponse>> {
    return asyncMiddleware<AxiosRequestConfig, AxiosResponse<TResponse>>([
      ...this._middleWares,
      async (requestConfig) => {
        return await this._cacheAction(
          requestConfig,
          async () => await this._axios.request(requestConfig),
          options
        );
      },
    ])(requestConfig);
  }
  fetch(
    requestConfig: AxiosRequestConfig,
    options?: Partial<HttpRequestOption>
  ) {
    return asyncMiddleware<AxiosRequestConfig, Response>([
      ...this._middleWares,
      async (requestConfig) => {
        return await this._cacheAction(
          requestConfig,
          async () =>
            await fetch(
              new URL(requestConfig.url ?? "", requestConfig.baseURL).href,
              {
                headers: requestConfig.headers as HeadersInit,
                body: JSON.stringify(requestConfig.data),
                method: requestConfig.method,
              }
            ),
          options
        );
      },
    ])(requestConfig);
  }
}

const HttpClientContext = {
  modelByKey: new Map(),
};

export const getHttpClientModel = <T extends typeof HttpClient>(
  model: T,
  middleWares?: HttpClientMiddleware[],
  namespace?: string
): InstanceType<T> => {
  if (namespace) {
    if (!HttpClientContext.modelByKey.get(namespace)) {
      HttpClientContext.modelByKey.set(
        namespace,
        new model(middleWares ?? []) as any
      );
    }
    return HttpClientContext.modelByKey.get(namespace);
  } else {
    if (!HttpClientContext.modelByKey.get(model)) {
      HttpClientContext.modelByKey.set(
        model,
        new model(middleWares ?? []) as any
      );
    }
    return HttpClientContext.modelByKey.get(model);
  }
};
export function isAxionsResponse(
  res: Response | AxiosResponse
): res is AxiosResponse {
  return "data" in res && res.data !== undefined;
}
export function isFeatchResponse(
  res: Response | AxiosResponse
): res is Response {
  return "body" in res && res.body !== undefined;
}

export function isAxiosError(
  error: unknown,
  status?: number
): error is AxiosError {
  const axiosError = error as AxiosError | undefined;
  if (axiosError?.isAxiosError) {
    if (status === undefined || axiosError.response?.status === status) {
      return true;
    }
  }
  return false;
}
