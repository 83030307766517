import { css } from "@emotion/react";
//fix row-grap 和column-grap 会在手机端有兼容性问题
export function rowGapUtils(count: number) {
  return css`
    > * {
      margin-bottom: ${count}px !important;
    }
    > *:last-child {
      margin-bottom: unset !important;
    }
  `;
}
export function columnGapUtils(count: number) {
  return css`
    > * {
      margin-right: ${count}px !important;
    }
    > *:last-child {
      margin-right: unset !important;
    }
  `;
}
