import { ObjectEntries, ObjectKeys, ObjectValues } from "@/types/object";
import { DeepMutable, DeepRecord, RecordKey } from "@/types/shared";
import { DropString } from "@/types/string";
import { Assign } from "@/types/utils";
import { isArray } from "lodash-es";
import { UnionToTuple } from "typescript-lodash/lib/common";
import { Concat } from "typescript-lodash/lib/string";
type UrlValue = string | number;

export function queryStringify<T extends Record<string, unknown>>(obj: T) {
  type Entries = UnionToTuple<ObjectEntries<DeepMutable<T>>>;
  type ArrMapToString<
    TValues extends unknown[],
    TKey extends UrlValue,
    TString extends string = ``
  > = TValues["length"] extends 0
    ? TString
    : TValues extends [infer Current, ...infer Other]
    ? ArrMapToString<
        Other,
        TKey,
        Concat<TString, `&${TKey}=${Current & UrlValue}`>
      >
    : TString;

  type EntriesMapToString<
    TArray extends unknown[],
    TString extends string = ``
  > = TArray["length"] extends 0
    ? TString
    : TArray extends [infer Current, ...infer Other]
    ? Current extends [string, UrlValue]
      ? EntriesMapToString<
          Other,
          Concat<TString, `&${Current[0]}=${Current[1]}`>
        >
      : Current extends [string, UrlValue[]]
      ? EntriesMapToString<
          Other,
          Concat<TString, ArrMapToString<Current[1], Current[0], "">>
        >
      : TString
    : TString;

  let query = [];
  if (obj) {
    for (let key in obj) {
      const value = obj[key];
      if (value === undefined) continue;
      if (isArray(value)) {
        value.forEach((item) => {
          query.push("".concat(key, "=", `${item}`));
        });
      } else {
        query.push("".concat(key, "=", `${value}`));
      }
    }
  }
  return query.join("&") as DropString<EntriesMapToString<Entries>, 1>;
}

export function keys<T extends {}>(obj: T) {
  return Object.keys(obj) as ObjectKeys<T>[];
}

export function entries<T extends {}>(obj: T) {
  return Object.entries(obj) as ObjectEntries<T>;
}

export function values<T extends {}>(obj: T) {
  return Object.values(obj) as ObjectValues<T>;
}

export function isObject(obj: unknown): obj is Record<string, unknown> {
  return obj != null && typeof obj === "object" && !Array.isArray(obj);
}

export function mergeObjects<T>(
  target: DeepRecord<T>,
  source: DeepRecord<T>,
  fn?: (
    item: T,
    key: string,
    parent: DeepRecord<T>,
    paths: readonly string[]
  ) => void,
  paths: string[] = []
): DeepRecord<T> {
  if (!isObject(target)) {
    throw new Error("target is not an object");
  }

  if (!isObject(source)) {
    throw new Error("source is not an object");
  }

  Object.keys(source).forEach((key) => {
    if (key === "__proto__") {
      return;
    }

    paths.push(key);

    const sourceItem = source[key];
    if (isObject(sourceItem)) {
      if (target[key] === undefined) {
        target[key] = {};
      }

      const targetItem = target[key];
      mergeObjects(
        targetItem as Record<string, T>,
        sourceItem as Record<string, T>,
        fn,
        paths
      );
    } else {
      if (fn) {
        fn(sourceItem as T, key, target, paths);
      } else {
        target[key] = sourceItem;
      }
    }

    paths.pop();
  });

  return target;
}

export function traverseObject<T>(
  obj: DeepRecord<T>,
  fn: (
    item: T,
    key: string,
    parent: DeepRecord<T>,
    paths: readonly string[]
  ) => void
): void {
  mergeObjects(obj, obj, fn);
}

export function flattenObject<T>(
  obj: DeepRecord<T>,
  separator = "."
): Record<string, T> {
  const result: Record<string, T> = {};

  traverseObject(obj, (item, key, parent, paths) => {
    result[paths.join(separator)] = item;
  });

  return result;
}

export function defineGetter<TObject, TKey extends keyof TObject>(
  obj: TObject,
  p: TKey,
  get: () => TObject[TKey]
): void {
  Object.defineProperty(obj, p, {
    get,
    enumerable: false,
    configurable: true,
  });
}

export function assign<T extends {}[]>(...args: T): Assign<T> {
  return Object.assign({}, ...args);
}

export function overwrite<TObject, TRewite extends Partial<TObject>>(
  obj: TObject,
  reWriteObject: TRewite
): TObject {
  return Object.assign({}, obj, reWriteObject);
}

export function omit<
  TObject extends Record<RecordKey, unknown>,
  TKey extends keyof TObject
>(data: TObject, keys: TKey[]): Omit<TObject, TKey> {
  return Object.fromEntries(
    Object.entries(data).filter(([keyName]) => {
      return !keys.includes(keyName as TKey);
    })
  ) as any;
}
export function pick<
  TObject extends Record<RecordKey, unknown>,
  TKey extends keyof TObject
>(data: TObject, keys: TKey[]): Pick<TObject, TKey> {
  return Object.fromEntries(
    Object.entries(data).filter(([keyName]) => {
      return keys.includes(keyName as TKey);
    })
  ) as any;
}
