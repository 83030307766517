/** @jsxImportSource @emotion/react */
import { css, Typography as MuiTypography } from "@mui/material";
import { ElementType, memo, ReactNode, useMemo } from "react";

export type TypographyProps = {
  align?: "center" | "inherit" | "justify" | "left" | "right";
  type?:
    | "title"
    | "subTitle"
    | "subTitle-bold"
    | "text"
    | "text-bold"
    | "subText"
    | "subText-bold";
  color?: string;
  fontSize?: string;
  textFormat?: string;
  textAlign?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";
  noWrap?: boolean;
  className?: string;
  children?: ReactNode;
  title?: string;
  component?: ElementType;
};
export default memo(function Typography(props: TypographyProps) {
  const {
    align,
    type,
    color,
    component = "div",
    fontSize,
    textFormat,
    textAlign,
    noWrap,
    className,
    children,
    ...restProps
  } = props;

  const fontStyle = useMemo(() => {
    switch (type) {
      case "title":
        return {
          fontSize: "20px",
          fontWeight: "bold",
        };
      case "subTitle":
        return {
          fontSize: "16px",
        };
      case "subTitle-bold":
        return {
          fontSize: "16px",
          fontWeight: "bold",
        };

      case "text-bold":
        return {
          fontSize: "14px",
          fontWeight: "bold",
        };
      case "text":
        return {
          fontSize: "14px",
        };
      case "subText":
        return {
          fontSize: "12px",
        };
      case "subText-bold":
        return {
          fontSize: "12px",
          fontWeight: "bold",
        };
      default:
        return {
          fontSize: "14px",
        };
    }
  }, [type]);

  return (
    <MuiTypography
      align={align}
      component={component}
      noWrap={noWrap}
      sx={fontStyle}
      className={className}
      color={color}
      css={css`
        text-decoration: ${textFormat};
        text-align: ${textAlign};
        font-size: ${fontSize} !important;
      `}
      {...restProps}
    >
      {children}
    </MuiTypography>
  );
});
