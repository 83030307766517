/** @jsxImportSource @emotion/react */
import { Spinner } from "@/components/Spinner";
import { createGuard } from "@/containers/Guards";
import { EncooHttpService } from "@/services/EncooHttpService";
import { useAuthStore } from "@/store/auth";
import { useLoginUserStore } from "@/store/loginUser";
import { css } from "@emotion/react";
import { memo, useEffect } from "react";

export const AuthLoadingView = memo(() => {
  const { setTokenStatus, setTokenInfo } = useAuthStore((state) => state);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    EncooHttpService.auth
      .wechatLogin(searchParams.get("code")!)
      .then((res) => {
        setTokenInfo(res);
      })
      .catch(() => {
        setTokenStatus("error");
      });
  }, [setTokenInfo, setTokenStatus]);

  return (
    <div
      css={css`
        padding-bottom: 44px;
      `}
      className="w-screen h-screen flex flex-col items-center justify-center"
    >
      <Spinner />
    </div>
  );
});

export const AuthLoadingGuard = createGuard({
  description: "无登录",
  runCondition: function () {
    const { tokenStatus } = useAuthStore.getState();
    const { isJump } = useLoginUserStore.getState();

    const searchParams = new URLSearchParams(window.location.search);
    return (
      !isJump && Boolean(tokenStatus === "unset" && searchParams.get("code"))
    );
  },
  render: () => <AuthLoadingView />,
  subscribe: (runGuard) => {
    useAuthStore.subscribe(() => runGuard());
  },
});
