/** @jsxImportSource @emotion/react */
import { useNotification } from "@/containers/Providers/Notification";
import { useNotificationStore } from "@/store/notification";
import { memo, useEffect } from "react";

export const MessageNotification = memo(() => {
  const notification = useNotification();
  const messge = useNotificationStore((state) => state.message);
  useEffect(() => {
    if (messge) {
      notification(messge);
    }
  }, [messge, notification]);
  return null;
});
