import { useChartQuotaStore } from "@/store/chartQuota";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

import { EncooHttpService } from "@/services/EncooHttpService";
import { dataURLtoFile } from "@/utils/file";

export interface ChartDrawingState {
  isLoading: boolean;
  selectedImage: string[];
  solitaireImage: string[];
  isNoMorePrompts: boolean;
  setIsLoading: (isLoading: boolean) => void;
  setSelectedImage: (selectedImage: string[]) => void;
  setIsNoMorePrompts: (isNoMorePrompts: boolean) => void;
  getlocalData: (localId: string, data: string) => Promise<string[]>;
  getRestQuota4: () => number;
}

export const useChartDrawingStore = create(
  subscribeWithSelector<ChartDrawingState>((set, get) => ({
    isLoading: false,
    selectedImage: [],
    solitaireImage: [],
    isNoMorePrompts: false,
    setIsLoading: (isLoading) => set({ isLoading }),
    setSelectedImage: (selectedImage) => {
      set({ selectedImage });
    },
    setIsNoMorePrompts: (isNoMorePrompts) => set({ isNoMorePrompts }),
    getlocalData: async (localId, data) => {
      const { selectedImage, setSelectedImage } = get();
      let localData = "";
      let imageUrls: string[] = [];
      if (data.indexOf("data:image") === 0) {
        if (data.indexOf("data:image/jpg;base64,") !== -1) {
          localData = data
            .replace(/\r|\n/g, "")
            .replace("data:image/jpg;base64,", "data:image/jpeg;base64,");
        } else {
          localData = data;
        }
      } else {
        localData = "data:image/jpeg;base64," + data.replace(/\r|\n/g, "");
      }
      const content = dataURLtoFile(localData, localId);

      const name = localId.split("/");

      const imgData = {
        fileName: name[name.length - 1],
        fileSize: content.size,
        fileContent: content,
        // contentType: "application/octet-stream",
        contentType: content.type,
      };

      const imageId = await EncooHttpService.chart.uploadUris(imgData);
      if (imageId) {
        const url = (await EncooHttpService.chart.markerFileUpload(imageId))
          ?.url;
        imageUrls = selectedImage.concat(url);
        setSelectedImage(imageUrls);
      }

      return imageUrls;
    },
    getRestQuota4: () => {
      const { quota4 } = useChartQuotaStore.getState();
      const restQuota = Number(
        Number(quota4?.max ?? 0) - Number(quota4?.used ?? 0)
      );
      return restQuota > 0 ? restQuota : 0;
    },
  }))
);
