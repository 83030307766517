import { HttpClient } from "@/services/apis/shared";
import { UserServicesType } from "@/services/models/user";

export class EncooUserServiceHttpClient extends HttpClient {
  public async getUserServices(payload: { valid?: boolean }) {
    const { data } = await this.request<UserServicesType[]>({
      method: "GET",
      url: `/payment/api/UserServices`,
      params: payload,
    });
    return data;
  }
}
