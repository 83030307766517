/** @jsxImportSource @emotion/react */
import { PageLoading } from "@/components/Loading";
import { routerConfigs } from "@/containers/Routers/configs";
import { useRunTaskActions } from "@/containers/Tasks";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { LazyExoticComponent, Suspense, memo, useEffect, useMemo } from "react";

export interface RouteElementProps {
  element:
    | LazyExoticComponent<() => JSX.Element | null>
    | React.LazyExoticComponent<
        React.MemoExoticComponent<() => EmotionJSX.Element | null>
      >;
  path: string;
}

export default memo(function LazyRouteElement(props: RouteElementProps) {
  const { path } = props;
  const route = useMemo(() => {
    return routerConfigs.find((item) => item.path === path);
  }, [path]);

  useRunTaskActions(route && "autoTasks" in route ? route.autoTasks ?? [] : []);

  useEffect(() => {
    // document.title = route?.name ?? "Beezy | 喳喳蜂";
    document.title = "Beezy | 喳喳蜂";
  }, []);

  return (
    <Suspense fallback={<PageLoading />}>
      <props.element />
    </Suspense>
  );
});
