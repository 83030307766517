/** @jsxImportSource @emotion/react */
import { RoleSkinProps } from "@/containers/Routers/Chart/skins/shared";
import { css } from "@emotion/react";
import { memo } from "react";

export const Basic = memo(function (props: RoleSkinProps) {
  const { children, error } = props;
  return (
    <div
      className="w-full"
      css={[
        css`
          padding: 14px 10px;
          border-radius: 10px;
          user-select: all;
          margin-right: auto;
          border-bottom-left-radius: unset;
          word-break: break-all;
        `,
        error &&
          css`
            border: 1px solid #ff9494;
            background-color: rgba(239, 68, 68, 0.1);
          `,
      ]}
    >
      {children}
    </div>
  );
});
