/** @jsxImportSource @emotion/react */
import IconSpin from "@assets/img/icon-spin.svg";
import { css, keyframes } from "@emotion/react";
import { Fragment, memo } from "react";
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = memo(() => {
  return (
    <Fragment>
      <img
        css={css`
          width: 60px;
          animation: ${spin} 1.2s infinite;
          animation-timing-function: linear;
        `}
        src={IconSpin}
        alt="登录"
      />

      <div
        css={css`
          margin-top: 20px;
        `}
      >
        登录中...
      </div>
    </Fragment>
  );
});
