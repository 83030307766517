import { HttpClient } from "@/services/apis/shared";
import { FeedbackTakePrize, InvitationTask, TakePrize } from "@/store/share";

export class EncooShareHttpClient extends HttpClient {
  public async getInvitationTask() {
    const { data } = await this.request<InvitationTask>({
      method: "GET",
      url: `/payment/api/InvitationTask`,
    });
    return data;
  }
  public async getPrizes(payload: TakePrize) {
    const { data } = await this.request<FeedbackTakePrize>({
      method: "POST",
      url: `/payment/api/InvitationTask/prizes`,
      data: payload,
    });
    return data;
  }
}
