import { StringDateType } from "@/types/shared";

export enum OrderStatusEnum {
  /// 刚刚创建的订单，等待确认
  "new" = "New",
  /// 订单已确认，等待客户付款。
  "awaitingPayment" = "AwaitingPayment",
  /// 客户已经完成了付款。
  "paid" = "Paid",
  /// 商家正在处理订单，准备发货。
  "Processing" = "Processing",
  /// 商家已经发货。
  "shipped" = "Shipped",
  /// 订单已经完成，客户已经确认收到商品。
  "completed" = "Completed",
  /// 订单已被取消，无法再次使用。
  "canceled" = "Canceled",
  /// 客户申请了退款，商家正在处理。
  "refunding" = "Refunding",
  /// 商家已经处理了退款请求，款项已退还给客户。
  "refunded" = "Refunded",
  /// 商家无法处理退款请求，退款失败。
  "refundFailed" = "RefundFailed",
  /// 订单超时未支付，已失效。
  "timeout" = "Timeout",
}

export enum OrderPaymentMethodEnum {
  weChatPay = "WeChatPay_JSAPI",
}

export interface PayOrder {
  id: string;
  orderTime: StringDateType;
  expiredTimeOfPayment: string;
  status: OrderStatusEnum;
  customerId: string;
  items: [
    {
      productId: string;
      productSnapshoot: {
        id: string;
        name: string;
        price: number;
        displayPrice: number;
        description: string;
        image: string;
      };
      quantity: number;
      price: number;
    }
  ];
  orderAmount: number;
  paymentMethod: OrderPaymentMethodEnum;
  payTime: StringDateType;
  paymentOrderNo: string;
  oriOrderAmount: number;
}
