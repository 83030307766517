import { Static, TObject } from "@sinclair/typebox";
import { produce } from "immer";
export interface GetStorageStoreType<StateType> {
  getItem<T extends keyof StateType>(key: T): StateType[T] | undefined;
  getItem<T extends keyof StateType>(
    key: T,
    defaultValue: StateType[T]
  ): StateType[T];
}

export function createStorage<T extends TObject<any>>(
  storeName: string,
  schema: T
) {
  type StateType = Static<typeof schema>;
  type StateKeyUnion = keyof StateType & string;

  return new (class StorageStore {
    private getStoreData() {
      return JSON.parse(
        localStorage.getItem(storeName) ?? "{}"
      ) as Partial<StateType>;
    }
    setItem<T extends StateKeyUnion>(key: T, value: StateType[T]) {
      // const valueSchema = schema["properties"][key];
      const item = { [key]: value };

      // if (Value.Check(Type.Object({ [key]: valueSchema }), item)) {
      const storeData = this.getStoreData();
      const draftData = produce(storeData, (draft) => {
        Object.assign(draft, item);
      });
      localStorage.setItem(storeName, JSON.stringify(draftData));
      // }
    }
    getItem<T extends StateKeyUnion>(key: T): StateType[T] | undefined;
    getItem<T extends StateKeyUnion>(key: T, b: StateType[T]): StateType[T];
    getItem<T extends StateKeyUnion>(
      key: T,
      defaultValue?: StateType[T]
    ): StateType[T] | undefined {
      const storeData = this.getStoreData();
      return storeData[key] ?? defaultValue;
    }
    removeItem<T extends StateKeyUnion>(key: T) {
      const storeData = this.getStoreData();
      const draftData = produce(storeData, (draft) => {
        Object.assign(draft, {
          [key]: undefined,
        });
      });
      localStorage.setItem(storeName, JSON.stringify(draftData));
    }
  })();
}
