/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { memo } from "react";

export const MaskImage = memo(
  (props: { src: string; className?: string; onClick?: () => void }) => {
    const { className, src, onClick } = props;
    return (
      <img
        src={src}
        alt=""
        onClick={onClick}
        css={css`
          max-width: 90%;
          max-height: 80vh;
          margin: 0 auto;
        `}
        className={className}
      />
    );
  }
);
