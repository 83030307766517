/** @jsxImportSource @emotion/react */
import { newGuid } from "@/utils/shared";
import { css } from "@emotion/react";
import { Slide } from "@mui/material";
import Alert from "@mui/material/Alert";
import { memoize } from "lodash-es";
import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { TransitionGroup } from "react-transition-group";

export const NotificationContext = createContext<{
  onOpen: (config: NotificationConfig) => void;
}>(undefined!);

export type NotificationType = "info" | "success" | "error" | "warning";
export interface NotificationConfig {
  duration?: number;
  key: string;
  type: NotificationType;
  title: ReactNode;
  description?: ReactNode;
}

export const NotificationProvider = memo(
  (props: { children?: ReactNode; duration?: number }) => {
    const { children, duration = 5 } = props;

    const [configs, setConfigs] = useState<NotificationConfig[]>([]);
    const onOpen = useCallback(
      (config: NotificationConfig) => {
        setConfigs((state) => [state, config].flat());

        setTimeout(() => {
          setConfigs((state) =>
            state.filter((item) => item.key !== config.key)
          );
        }, (config.duration ?? duration) * 1000);
      },
      [duration]
    );

    const onClose = useMemo(
      () =>
        memoize((key: string) => () => {
          setConfigs((state) => state.filter((item) => item.key !== key));
        }),
      []
    );

    const contextValue = useMemo(() => {
      return {
        onOpen,
      };
    }, [onOpen]);

    return (
      <NotificationContext.Provider value={contextValue}>
        {configs.length ? (
          <div
            className="fixed top-0 w-full left-0 right-0"
            css={css`
              z-index: 1000;
              display: "block";
            `}
          >
            <TransitionGroup>
              {configs.map((config) => (
                <Slide
                  direction="down"
                  in={true}
                  mountOnEnter
                  unmountOnExit
                  key={config.key}
                >
                  <Alert
                    css={css`
                      margin: 10px 4px 0 4px;
                    `}
                    severity={config.type}
                    onClose={onClose(config.key)}
                  >
                    {config.title}
                  </Alert>
                </Slide>
              ))}
            </TransitionGroup>
          </div>
        ) : null}

        {children}
      </NotificationContext.Provider>
    );
  }
);

export function useNotification() {
  const { onOpen } = useContext(NotificationContext);
  return useCallback(
    (config: Omit<NotificationConfig, "key">) => {
      onOpen(Object.assign({ key: newGuid() }, config));
    },
    [onOpen]
  );
}
