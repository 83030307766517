/** @jsxImportSource @emotion/react */
import SvgIcon from "@/components/SvgIcon";
import { Basic } from "@/containers/Routers/Chart/skins/normal/_internal";
import { createSkinConfig } from "@/containers/Routers/Chart/skins/shared";
import {
  ConversationChannelEnum,
  ConversationMessageExtensionEnum,
  ConversationMessageRoleEnum,
} from "@/services/models/chart";
import { ReactComponent as IconSend } from "@assets/img/icon-normalSend.svg";
import { ReactComponent as IconGpt3 } from "@assets/img/mode/icon-gpt3.svg";
import { ReactComponent as IconGpt4 } from "@assets/img/mode/icon-gpt4.svg";
import { ReactComponent as IconImage } from "@assets/img/mode/icon-image.svg";
import { ReactComponent as IconLongText } from "@assets/img/mode/icon-longText.svg";
export const normalSkin = createSkinConfig({
  type: ConversationMessageRoleEnum["normal"],
  name: "普通模式",
  icon: IconSend,
  description: "日常使用-常规对话模式",
  skinElementContainer: {
    basic: Basic,
  },
  avatar: (messageState) => (
    <SvgIcon
      SvgComponent={
        messageState.channel === ConversationChannelEnum["gpt3"]
          ? IconGpt3
          : messageState.extensions?.[0] ===
            ConversationMessageExtensionEnum["highQualityImage"]
          ? IconImage
          : messageState.extensions?.[0] ===
            ConversationMessageExtensionEnum["longText"]
          ? IconLongText
          : IconGpt4
      }
      value={20}
    />
  ),
});
