/** @jsxImportSource @emotion/react */
import { Basic } from "@/containers/Routers/Chart/skins/cat/_internal";
import { createSkinConfig } from "@/containers/Routers/Chart/skins/shared";
import { ConversationMessageRoleEnum } from "@/services/models/chart";
import { ReactComponent as IconSend } from "@assets/img/icon-catSend.svg";
import ImgCat from "@assets/img/skin/cat.png";
export const catSkin = createSkinConfig({
  type: ConversationMessageRoleEnum["cat"],
  name: "召唤猫娘",
  description: "可爱的猫咪，提供问答服务喵呜～",
  icon: IconSend,
  skinElementContainer: {
    basic: Basic,
  },
  avatar: () => <img className="w-full h-full" src={ImgCat} alt="" />,
});
