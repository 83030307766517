import { UseConfirmType, useConfirm } from "@/containers/Providers/Confirm";
import { UseMaskType, useMask } from "@/containers/Providers/Mask";
import { UseNavigateType, useNavigateTo } from "@/hooks/navigation";
import { createStorage } from "@/utils/localStorage";
import { newGuid } from "@/utils/shared";
import { Type } from "@sinclair/typebox";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export type TasksTypeAction = {
  mask: UseMaskType;
  confirm: UseConfirmType;
  navigate: UseNavigateType;
};

export type TaskStateType = {
  name: string;
  key: string;
  description?: string;
  runCondition: () => boolean;
  onRun: (action: TasksTypeAction, next: () => void) => void;
  subscribe?: (runTask: () => void) => void;
};

const TaskStorageStateSchema = Type.Object({
  drawing: Type.String(),
  promotional: Type.String(),
  temporary: Type.String(),
  shareDate: Type.String(),
  magicIntroduce: Type.Boolean(),
  pcIntroduceVersion: Type.String(),
});
export const TaskStorage = createStorage("bee-tasks", TaskStorageStateSchema);

const taskSubscribeMap = new Map<string, () => void>();

export const createTask = (
  config: Omit<TaskStateType, "key">
): TaskStateType => {
  const key = newGuid();
  config.subscribe?.(() => {
    const runAction = taskSubscribeMap.get(key);
    runAction?.();
  });
  return Object.assign({ key }, config);
};

const useRunTask = function () {
  const confirm = useConfirm();
  const mask = useMask();
  const navigateTo = useNavigateTo();
  return useCallback(
    (task: TaskStateType, callback?: () => void) => {
      const taskCallback = () => {
        callback?.();
      };
      if (task.runCondition()) {
        task.onRun(
          {
            confirm,
            mask,
            navigate: navigateTo,
          },
          taskCallback
        );
      } else {
        taskCallback();
      }
    },
    [confirm, mask, navigateTo]
  );
};

const useAutoRunTask = function () {
  const [taskList, setTaskList] = useState<
    (TaskStateType & { runKey: string })[]
  >([]);
  const runTask = useRunTask();
  const runKeyRef = useRef("");
  const addTask = useCallback(
    (task: TaskStateType) =>
      setTaskList((tasks) => [
        ...tasks,
        Object.assign({ runKey: newGuid() }, task),
      ]),
    []
  );

  useEffect(() => {
    const task = taskList[0];
    if (task && runKeyRef.current !== task.runKey) {
      runKeyRef.current = task.runKey;
      runTask(task, () => {
        setTaskList((tasks) =>
          tasks.filter((item) => item.runKey !== task.runKey)
        );
      });
    }
  }, [runTask, taskList]);

  return useMemo(() => {
    return {
      add: addTask,
    };
  }, [addTask]);
};

export const useRunTaskActions = function <T extends TaskStateType>(
  tasks: T[]
) {
  const runTask = useRunTask();
  const [taskIndex, setTaskIndex] = useState(0);
  const { add } = useAutoRunTask();

  const handleSubscribe = useCallback(() => {
    tasks.forEach((item) => {
      if (item.subscribe) {
        taskSubscribeMap.set(item.key, () => add(item));
      }
    });
  }, [add, tasks]);

  useEffect(() => {
    const task = tasks[taskIndex];
    if (task) {
      runTask(task, () => {
        const nextIndex = taskIndex + 1;
        setTaskIndex(nextIndex);
        if (nextIndex === tasks.length) {
          handleSubscribe();
        }
      });
    }
  }, [handleSubscribe, runTask, taskIndex, tasks]);
};
